import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import { runEngine } from "../../../framework/src/RunEngine";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "../../../framework/src/Utilities";
// Customizable Area End

export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');

export interface ISelectDoc {
  onChange: (file: File) => void;
  label: string;
  errorName: string;
  selectedDocInfo: File | null;
  testId: string;
}

export interface Props {
  navigation: any;
  id: string;
}

export interface S {
  // Customizable Area Start
  token: string;
  userType: string;
  fullName: string;
  bankName: string;
  agencyName: string;
  serviceProviderName: string;
  firstName: string;
  lastName: string;
  userName: string;
  email: string;
  password: string;
  phone: string;
  passwordHelperText: string;
  bussinessDoc: any;
  taxRegistrationDoc: any;
  licenseDoc: any;
  activated: boolean;
  unifyingNumber: string;
  commercialRegNo: string;
  otpVerify: boolean;
  errors: any,
  currentLanguage: 'en' | 'ar';
  activeStep: number;
  accountType: string;
  accountSubType: string;
  showPassword: boolean;
  verificationToken: string;
  verificationCode: Array<string>;
  anchorEl: HTMLElement | null;
  passwordFieldChecklist: Array<string>;
  isEditBasicInfo: boolean;
  isEditDetailInfo: boolean;
  isEditDebtPortfolio: boolean;
  isEditBusinessInterest: boolean;
  terms: boolean;
  termsError: boolean;
  basicInformation: {
    id?: number,
    fullName: string;
    bankName: string;
    agencyName: string;
    serviceProviderName: string;
    email: string;
    password: string;
    phone: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  };
  detailInformation: {
    date: string; 
    month: string; 
    year: string;
    nationalId: string;
    idProof: File | null;
    addressProof: File | null;
    commercialRegistration: string;
    bankLicenseNumber: string;
    commercialRegistrationDocument: File | null;
    bankLicenseDocument: File | null;
    agencyRegistrationNumber: string;
    agencyLicenseNumber: string;
    agencyRegistrationDocument: File | null;
    agencyLicenseDocument: File | null;
    registrationNumber: string;
    serviceLicenseNumber: string;
    registrationDocument: File | null;
    serviceLicenseDocument: File | null;
  };
  debtPortfolio: {
    portfolioSize: string;
    accountCount: string;
    averageBalance: string;
    debtTypes: string;
    debtAge: string;
    recoveryStatus: string;
  };
  businessInterest: {
    debtTypes: string;
    portfolioSize: string;
    debtAgeRange: string;
    geographicPreference: string;
  }
}

export interface SS {
  // Customizable Area Start
  id: any;
  // Customizable Area End
}

export default class SellerRegistrationController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  data: any[];
  passwordRegex: RegExp;
  emailRegex: RegExp;
  phoneRegex: RegExp;
  accountApiCallId: any;
  validationCallId: string = "";
  getProfileCallId: string = "";
  verificationApiCallId: string="";
  accountDetailApiCallId: string="";
  accountDebtPortfolioApiCallId: string="";
  activateUserApiCallId: string="";
  accountBusinessInterestApiCallId: string="";
  accountUpdateApiCallId: string="";
  firstNameLabel: string;
  userNameLabel: string;
  lastnameLabel: string;
  phoneLabel: string;
  emailLabel: string;
  passwordLabel: string;
  rePasswordLabel: string;
  signUpbtn: string;
  fieldMapping: any;
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);
    this.isNullOrBlank = this.isNullOrBlank.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.NavigationPayLoadMessage),
    ];
    runEngine.attachBuildingBlock(this, this.subScribedMessages);

    this.state = {
      // Customizable Area Start
      token: "",
      userType: "provider",
      otpVerify: false,
      fullName: "",
      bankName: "",
      agencyName: "",
      serviceProviderName: "",
      firstName: "",
      lastName: "",
      userName: "",
      email: "",
      password: "",
      phone: "",
      passwordHelperText: "",
      bussinessDoc: null,
      taxRegistrationDoc: null,
      licenseDoc: null,
      anchorEl: null,
      activated: true,
      unifyingNumber: "",
      commercialRegNo: "",
      activeStep: 0,
      accountType: "debtOwner",
      accountSubType: "individual",
      showPassword: false,
      verificationToken: "",
      passwordFieldChecklist: ["#94A3B8","#94A3B8","#94A3B8","#94A3B8"],
      isEditBasicInfo: false,
      isEditDetailInfo: false,
      isEditDebtPortfolio: false,
      isEditBusinessInterest: false,    
      verificationCode: ["", "", "", "", "", ""],
      terms: false,
      termsError: false,
      errors: {
        FullName: "",
        BankName: "",
        AgencyName: "",
        ServiceProviderName: "",
        Email: "",
        Password: "",
        Phone: "",
        City: "",
        State: "",
        Country: "",
        Zip: "",
        OTP: "",
        NationalId: "",
        CommercialRegistration: "",
        BankLicenseNumber: "",
        AgencyRegistrationNumber: "",
        AgencyLicenseNumber: "",
        RegistrationNumber: "",
        ServiceLicenseNumber: ""
      },
      currentLanguage: "en",
      basicInformation: {
        fullName: "",
        bankName: "",
        agencyName: "",
        serviceProviderName: "",
        email: "",
        password: "",
        phone: "",
        city: "",
        state: "",
        country: "",
        zip: ""
      },
      detailInformation: {
        date: "", 
        month: "", 
        year: "",
        nationalId: "",
        idProof: null,
        addressProof: null,
        commercialRegistration: "",
        bankLicenseNumber: "",
        commercialRegistrationDocument: null,
        bankLicenseDocument: null,
        agencyRegistrationNumber: "",
        agencyLicenseNumber: "",
        agencyRegistrationDocument: null,
        agencyLicenseDocument: null,
        registrationNumber: "",
        serviceLicenseNumber: "",
        registrationDocument: null,
        serviceLicenseDocument: null
      },
      debtPortfolio: {
        portfolioSize: "",
        accountCount: "",
        averageBalance: "",
        debtTypes: "",
        debtAge: "",
        recoveryStatus: ""
      },
      businessInterest: {
        debtTypes: "",
        portfolioSize: "",
        debtAgeRange: "",
        geographicPreference: ""
      }
      // Customizable Area End
    };

    // Customizable Area Start
    this.data = [];
    this.emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    this.passwordRegex =
      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,30}$/;
    this.phoneRegex = /^\d{10,}$/;

    this.firstNameLabel = configJSON.labelFirstName;
    this.userNameLabel = configJSON.labelUserName;
    this.lastnameLabel = configJSON.lastName;
    this.phoneLabel = configJSON.labelPhone;
    this.emailLabel = configJSON.labelEmail;
    this.passwordLabel = configJSON.labelPassword;
    this.rePasswordLabel = configJSON.labelRePassword;
    this.signUpbtn = configJSON.btnTextSignUp;

    this.fieldMapping = {
      email: configJSON.Email,
      user_name: configJSON.UserName,
      OTP: configJSON.OTP,
      tax_registration: configJSON.TaxRegistrationDocument,
      license_certification: configJSON.LicenseOrCertification,
      business_registration: configJSON.BusinessRegistrationDocument,
      commercial_registration_number: configJSON.CommercialRegistrationNumber,
    }

    // Customizable Area End
  }

  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    const activeStep = this.setActiveStep();
    const status = localStorage.getItem('signupStatus');
    if (status) {
      this.getProfile();
    }
    this.setState({ currentLanguage: language, activeStep });
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );

      switch (apiRequestCallId) {
        case this.validationCallId:
          this.validateResponseData(responseJson);
          break;
        case this.accountApiCallId:
          this.handleBasicInformationUpdate(responseJson);
        break;
        case this.verificationApiCallId:
          this.handleVerificationResponse(responseJson);
          break;
        case this.accountDetailApiCallId:
          this.handleAccountDetailResponse(responseJson);
          break;
        case this.accountDebtPortfolioApiCallId:
          this.handleResponse(responseJson);
          break;
        case this.accountBusinessInterestApiCallId:
          this.handleResponse(responseJson);
          break;  
        case this.accountUpdateApiCallId:
          this.handleBasicInformationUpdate(responseJson);
          break;
        case this.activateUserApiCallId:
          if (responseJson.data) {
            this.handleActivateAccount()
          }
          break;
        case this.getProfileCallId:
          if (responseJson.data) {
            this.handleBasicData(responseJson.data);
            this.handleDetailInfoData(responseJson.data);
          }
      }

    }
    // Customizable Area End
  }

  validateResponseData(responseJson: any) {
    this.data = responseJson.data;
    if (this.data && this.data.length !== 0) {
      let responseData = this.data[0];

      if (responseData.password_validation_regexp) {
        this.passwordRegex = new RegExp(
          responseData.password_validation_regexp
        );
      }

      if (responseData.password_validation_rules) {
        this.setState({
          passwordHelperText: responseData.password_validation_rules,
        });
      }

      if (responseData.email_validation_regexp) {
        this.emailRegex = new RegExp(responseData.email_validation_regexp);
      }
    }
  }

  // Customizable Area Start

  setActiveStep () {
    const status = localStorage.getItem('signupStatus');
    let activeStep = 0;
    switch (status) {
      case 'verification':
        activeStep = 3
        break;
      case 'detailed_information':
        activeStep = 4
        break;
      case 'debt_portfolio':
        activeStep = 5
        break;
      case 'business_interest':
        activeStep = 5
        break;
      case 'finalize':
        activeStep = 6
        break;
    }
    return activeStep;
  }

  validateBankDetailInfo() {
    let error = false;
    const { detailInformation } = this.state;
    if(this.isNullOrBlank(detailInformation.commercialRegistration, configJSON.CommercialRegistration)) {
      this.setError(configJSON.CommercialRegistration);
      error = true;
    }
    if(this.isNullOrBlank(detailInformation.bankLicenseNumber, configJSON.BankLicenseNumber)) {
      this.setError(configJSON.BankLicenseNumber);
      error = true;
    }
    return error;
  }

  validateAgencyDetailInfo () {
    let error = false;
    const { detailInformation } = this.state;

    if(this.isNullOrBlank(detailInformation.agencyRegistrationNumber, configJSON.AgencyRegistrationNumber)) {
      this.setError(configJSON.AgencyRegistrationNumber);
      error = true;
    }
    if(this.isNullOrBlank(detailInformation.agencyLicenseNumber, configJSON.AgencyLicenseNumber)) {
      this.setError(configJSON.AgencyLicenseNumber);
      error = true;
    }
    return error;
  }

  validateServiceProviderDetailInfo () {
    let error = false;
    const { detailInformation } = this.state;

    if(this.isNullOrBlank(detailInformation.registrationNumber, configJSON.RegistrationNumber)) {
      this.setError(configJSON.RegistrationNumber);
      error = true;
    }
    if(this.isNullOrBlank(detailInformation.serviceLicenseNumber, configJSON.ServiceLicenseNumber)) {
      this.setError(configJSON.ServiceLicenseNumber);
      error = true;
    }
    return error;
  }

  validateDetailInformation() {
    let error = false;
    const { detailInformation } = this.state;
    if (this.showPersonalDetail() && this.isNullOrBlank(detailInformation.nationalId, configJSON.NationalId)) {
      this.setError(configJSON.NationalId);
      error = true;
    }

    if (this.showBankDetail()) {
      error = this.validateBankDetailInfo();
    }

    if (this.showAgencyDetail()) {
     error = this.validateAgencyDetailInfo();
    }

    if (this.showServiceProviderDetail()) {
     error = this.validateServiceProviderDetailInfo();
    }

    if (!error) {
      this.submitDetailInformation();
    }
  }

  handleBasicInformationUpdate = (responseJson: any) => {
    if (!responseJson.errors && !responseJson.error) {
      if (this.state.activeStep !== 6) {
        this.saveSessionStorageToken(responseJson);
        this.handleNextStep();
      } else {
        this.handleFinalReviewCancel();
      }
    } else {
      this.handleApiError(responseJson)
    }
  }

  handleAccountDetailResponse = (responseJson: any) => {
    if (this.state.activeStep !== 6) {
      this.handleNextStep();
    } else {
      this.handleFinalReviewCancel();
    }
  }

  handleResponse = (responseJson: any) => {
    if (responseJson.data) {
      if (this.state.activeStep !== 6) {
        if (this.showDebtPortfolio()) {
          this.handleDebtPortfolioData(responseJson.data);
        } else {
          this.handleBusinessProfileData(responseJson.data);
        }
        this.handleNextStep();
      } else {
        this.handleFinalReviewCancel();
      }
    }
  }

  handleVerificationResponse = (responseJson: any) => {
    if (responseJson.user && responseJson.user.data) {
      this.setState({ otpVerify: responseJson.user.data.attributes.email_otp_verify})
      this.handleNextStep();
    } else {
      let errors = responseJson;
      if (responseJson.message) {
        errors = [{ 'OTP': responseJson.message }];
      } else {
        if (!Array.isArray(responseJson.errors)) {
          errors = [{ 'OTP': responseJson.errors }];
        }
      }
      this.handleApiError({ errors });
    }
  }

  handleBasicData (data: any) {
    this.setState({ basicInformation: {...this.state.basicInformation,
        id: data.attributes.id,
        fullName: data.attributes.full_name,
        bankName: data.attributes.bank_name,
        agencyName: data.attributes.agency_name,
        serviceProviderName: data.attributes.service_provider_name,
        email: data.attributes.email,
        password: data.attributes.password,
        phone: data.attributes.phone_number,
        city: data.attributes.city,
        state: data.attributes.state,
        country: data.attributes.country,
        zip: data.attributes.zipcode
    } })
  }

  handleDetailInfoData (data: any) {
    if (this.showPersonalDetail()) {
      this.setState({ detailInformation: {...this.state.detailInformation,
        date: data.attributes.date_of_birth ? data.attributes.date_of_birth.split("-")[0] : "",
        month: data.attributes.date_of_birth ? data.attributes.date_of_birth.split("-")[1] : "",
        year: data.attributes.date_of_birth ? data.attributes.date_of_birth.split("-")[2] : "",
        nationalId: data.attributes.license_number,
        idProof: data.attributes.id_proof,
        addressProof: data.attributes.address_proof 
      }}); 
    }
    if (this.showBankDetail()) {
      this.setState({ detailInformation: {...this.state.detailInformation,
        commercialRegistration: data.attributes.commercial_number,
        bankLicenseNumber: data.attributes.license_number,
        commercialRegistrationDocument: data.attributes.id_proof,
        bankLicenseDocument: data.attributes.address_proof,
      }});
    }
    if (this.showAgencyDetail()) {
      this.setState({ detailInformation: {...this.state.detailInformation,
      agencyRegistrationNumber: data.attributes.commercial_number,
      agencyLicenseNumber: data.attributes.license_number,
      agencyRegistrationDocument: data.attributes.id_proof,
      agencyLicenseDocument: data.attributes.address_proof 
    }})
    }
    if (this.showServiceProviderDetail()) {
      this.setState({ detailInformation: {...this.state.detailInformation,
        registrationNumber: data.attributes.commercial_number,
        serviceLicenseNumber: data.attributes.license_number,
        registrationDocument: data.attributes.id_proof,
        serviceLicenseDocument: data.attributes.address_proof
    }})
    }
  }

  handleDebtPortfolioData (data: any) {
    this.setState({ debtPortfolio: { ...this.state.debtPortfolio,
      portfolioSize: data.attributes.portfolio_size,
      accountCount: data.attributes.account_size,
      debtAge: data.attributes.dept_age,
      averageBalance: data.attributes.average_balance,
      debtTypes: data.attributes.dept_type,
      recoveryStatus: data.attributes.recovery_status
    }});
  }

  handleBusinessProfileData (data: any) {
    this.setState({ businessInterest: { ...this.state.businessInterest,
      debtTypes: data.attributes.dept_type,
      portfolioSize: data.attributes.portfolio_size,
      debtAgeRange: data.attributes.dept_age_range,
      geographicPreference: data.attributes.geo_preference
    }});
  }

  handleApiError = (responseJson: any) => {
    if (responseJson.errors) {
      const field = Object.keys(responseJson.errors[0])[0]
      const error = Object.values(responseJson.errors[0])[0]
      this.setError(this.fieldMapping[field], error)
    }
  }

  handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handlePopoverClose = () => {
    this.setState({ anchorEl: null });
  };

  async handleActivateAccount () {
    const token = await getStorageData('accessToken');
    await removeStorageData('signupStatus');
    await removeStorageData('accessSignupToken');
    if (token) {
      this.handleRoute("UserProfile");
    } else {
      this.handleRoute("SellerLogin");
    }
  }

  handleRoute(route: string) {
    const msg: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);

    msg.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(msg);
  }

  saveSessionStorageToken = async (responseJson: any) => {
    await setStorageData("userId", responseJson.data.id);
    await setStorageData("accessSignupToken", responseJson.meta.token);
    await setStorageData("otpToken", responseJson.meta.email_otp_token);
  };

  getProfile = async () => {
    const headers = {
        "Content-Type": configJSON.validationApiContentType,
        "token": await getStorageData("accessToken")
    }

    const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
    );

    this.getProfileCallId = requestMessage.messageId;

    requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        configJSON.fetchProfileEndPoint
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(headers)
    );

    requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.validationApiMethodType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
};

  setInitialField (formdata: FormData) {
    if (this.showPersonalDetail()) {
      formdata.append("[data][attributes][full_name]", this.state.basicInformation.fullName);
    }
    if (this.showBankDetail()) {
      formdata.append("[data][attributes][bank_name]", this.state.basicInformation.bankName);
    }
    if (this.showAgencyDetail()) {
      formdata.append("[data][attributes][agency_name]", this.state.basicInformation.agencyName);
    }
    if (this.showServiceProviderDetail()) {
      formdata.append("[data][attributes][service_provider_name]", this.state.basicInformation.serviceProviderName);
    }
  }

  setBasicInfoData(formdata: FormData) {
    formdata.append("[data][type]", "email_account");
    this.setInitialField(formdata)
    formdata.append("[data][attributes][full_phone_number]", this.state.basicInformation.phone);
    formdata.append("[data][attributes][country]", this.state.basicInformation.country);
    formdata.append("[data][attributes][state]", this.state.basicInformation.state);
    formdata.append("[data][attributes][city]", this.state.basicInformation.city);
    formdata.append("[data][attributes][zipcode]", this.state.basicInformation.zip);
    formdata.append("[data][attributes][password]", this.state.basicInformation.password);
    formdata.append("[data][attributes][email]", this.state.basicInformation.email);
    formdata.append("[data][attributes][activated]", "true");
  }

  submitData() {
    const headers = {};
    const accountType = this.state.accountType === "debtOwner" ? 'debt_owner' : 'debt_buyer';
    let formdata = new FormData();
    formdata.append("[data][attributes][user_type]", accountType);
    formdata.append("[data][attributes][sub_type]", this.state.accountSubType === "serviceProvider" ? "service_provider" : this.state.accountSubType);
    this.setBasicInfoData(formdata);
  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  updateData() {
    const { isEditBasicInfo, isEditDetailInfo, isEditDebtPortfolio, isEditBusinessInterest } = this.state;
    if (isEditBasicInfo) {
      this.validateBasicInformation();
    }
    if (isEditDetailInfo) {
      this.validateDetailInformation();
    }
    if (isEditDebtPortfolio) {
      this.submitDebtPortfolio();
    }
    if (isEditBusinessInterest) {
      this.submitBusinessInterest();
    }
  }

  async updateBasicInformation() {
    const userId = await getStorageData("userId");
    const headers = {};
    let formdata = new FormData();
    this.setBasicInfoData(formdata);
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountUpdateApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountsAPiEndPoint+userId
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeverifyEmail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async verifyWithOtp () {
    const headers = { "token": await getStorageData("accessSignupToken"), "Content-Type": "application/json"};
    const { verificationCode } = this.state;
    const verificationData = { data: 
      { otp: verificationCode.join(""), email_otp_token: await getStorageData("otpToken") }, 
      type: "email_account" }
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.verificationApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.verifyEmailAPiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(verificationData)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeverifyEmail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  setuploadFields (formdata: FormData) {
    const { detailInformation } = this.state;
    if (this.showPersonalDetail()) {
      formdata.append("address_proof_image", (detailInformation?.addressProof || "")  as Blob);
      formdata.append("id_proof_image", (detailInformation?.idProof || "") as Blob);
    }
    if (this.showBankDetail()) {
      formdata.append("address_proof_image", detailInformation?.bankLicenseDocument  as Blob);
      formdata.append("id_proof_image", detailInformation?.commercialRegistrationDocument as Blob);

    }
    if (this.showAgencyDetail()) {
      formdata.append("address_proof_image", detailInformation?.agencyLicenseDocument  as Blob);
      formdata.append("id_proof_image", detailInformation?.agencyRegistrationDocument as Blob);

    }
    if (this.showServiceProviderDetail()) {
      formdata.append("address_proof_image", detailInformation?.registrationDocument  as Blob);
      formdata.append("id_proof_image", detailInformation?.serviceLicenseDocument as Blob);
    }
  }

  setCommericialNumber (formdata: FormData) {
    const { detailInformation } = this.state;

    if (this.showBankDetail()) {
      formdata.append("commercial_number", detailInformation.commercialRegistration);
    }
    if (this.showAgencyDetail()) {
      formdata.append("commercial_number", detailInformation.agencyRegistrationNumber); 
    }
    if (this.showServiceProviderDetail()) {
      formdata.append("commercial_number", detailInformation.registrationNumber); 
    }
  }

  setLicenseNumber (formdata: FormData) {
    const { detailInformation } = this.state;
    if (this.showPersonalDetail()) {
      formdata.append("license_number", detailInformation.nationalId);
    }
    if (this.showBankDetail()) {
      formdata.append("license_number", detailInformation.bankLicenseNumber);
    }
    if (this.showAgencyDetail()) {
      formdata.append("license_number", detailInformation.agencyLicenseNumber); 
    }
    if (this.showServiceProviderDetail()) {
      formdata.append("license_number", detailInformation.serviceLicenseNumber); 
    }
  }

  async submitDetailInformation () {
    const token = (await getStorageData("accessToken")) || (await getStorageData("accessSignupToken"))
    const headers = { "token": token};
    const { detailInformation } = this.state;
    let formdata = new FormData();

    formdata.append("date_of_birth", detailInformation.date+"-"+detailInformation.month+"-"+detailInformation.year);
    this.setCommericialNumber(formdata);
    this.setLicenseNumber(formdata);
    this.setuploadFields(formdata);  
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.accountDetailApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.accountDetailInformationApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formdata
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  }

  async activateUserAccount () {
    const token = (await getStorageData("accessToken")) || (await getStorageData("accessSignupToken"))
    const headers = { "token": token, "Content-Type": "application/json"};
    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.activateUserApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.activateUserAccountApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeverifyEmail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage)
  }

  async submitDebtPortfolio () {
    const token = (await getStorageData("accessToken")) || (await getStorageData("accessSignupToken"))
    const headers = { "token": token, "Content-Type": "application/json"};
    const { debtPortfolio } = this.state;
    const data = {   
      "dept_portfolio": {
        "portfolio_size": debtPortfolio.portfolioSize,
        "account_size": debtPortfolio.accountCount,
        "dept_age": debtPortfolio.debtAge,
        "average_balance": debtPortfolio.averageBalance,
        "dept_type": debtPortfolio.debtTypes,
        "recovery_status": debtPortfolio.recoveryStatus
      },
  }    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountDebtPortfolioApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.debtPortfolioApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  async submitBusinessInterest () {
    const token = (await getStorageData("accessToken")) || (await getStorageData("accessSignupToken"))
    const headers = { "token": token, "Content-Type": "application/json"};
    const { businessInterest } = this.state;
    const data = {   
      "business_interest":{
        "dept_type": businessInterest.debtTypes, 
        "dept_age_range": businessInterest.debtAgeRange, 
        "portfolio_size": businessInterest.portfolioSize, 
        "geo_preference": businessInterest.geographicPreference
      }
    }    
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.accountBusinessInterestApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.businessInterestApiEndPoint
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      JSON.stringify(data)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeAddDetail
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);

  }

  handleAccountType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ accountType: event.target.value });
  }

  handleAccountSubType = (event: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ accountSubType: event.target.value });
  }

  handleClickShowPassword = () => {
    this.setState((preState) => ({
      showPassword: !preState.showPassword
    }))
  }

  isEditBasicInfo = () => {
    this.setState({ isEditBasicInfo: true});
  }

  isEditDetailInfo = () => {
    this.setState({ isEditDetailInfo: true });
  }

  isEditDebtPortfolio = () => {
    this.setState({ isEditDebtPortfolio: true });
  }

  isEditBusinessInterest = () => {
    this.setState({ isEditBusinessInterest: true });
  }

  showPersonalDetail = () => {
    const { accountType, accountSubType } = this.state;
    return (accountType === "debtOwner" && accountSubType === "individual") || 
    (accountType === "debtBuyer" && (accountSubType === "investor" || accountSubType === "individual" ))
  }

  showBankDetail = () => {
    const { accountType, accountSubType } = this.state;
    return (accountType === "debtOwner" && accountSubType === "bank") || 
    (accountType === "debtBuyer" && accountSubType === "bank")
  }

  showAgencyDetail = () => {
    const { accountType, accountSubType } = this.state;
    return (accountType === "debtOwner" && accountSubType === "agency")
  }

  showServiceProviderDetail = () => {
    const { accountType, accountSubType } = this.state;
    return (accountType === "debtBuyer" && accountSubType === "serviceProvider")
  }

  showDebtPortfolio = () => {
    const { accountType } = this.state;
    return accountType === "debtOwner";
  }

  showBusinessInterest = () => {
    const { accountType } = this.state;
    return accountType === "debtBuyer";
  }

  handleVerificationCode = (event: React.ChangeEvent<HTMLInputElement>, verificationIndex: number) => {
    const value = event.target.value;
    if (/^\d?$/.test(value)) {
      const newCode = [...this.state.verificationCode];
      newCode[verificationIndex] = value;
      this.setState({ verificationCode: newCode});

      // Focus on next input field
      if (value && verificationIndex < 5) {
        document.getElementById(`digit-${verificationIndex + 1}`)?.focus();
      }
    }
  };  

  isNullOrBlank(string: string, field: string) {
    const convertedField = this.getConvertedErrorField(field);
    const errorMsg = this.state.currentLanguage === configJSON.EN ? `${convertedField} is required` : `مطلوب ${convertedField}`;
    if (string === null || string.length === 0) {
      this.setState((prevState) => ({
        errors: {
          ...prevState.errors,
          [field]: `${errorMsg}`,
        },
      }));
      return true;
    }
    return false;

  }

  getConvertedErrorField = (keyName: string) => {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    switch (keyName) {
      case configJSON.Email: return languageData.Seller.Email;
      case configJSON.FirstName: return languageData.Seller.FirstName;
      case configJSON.LastName: return languageData.Seller.LastName;
      case configJSON.PhoneNumber: return languageData.Seller.PhoneNumber;
      case configJSON.UserName: return languageData.Seller.UserName;
      case configJSON.Password: return languageData.Seller.Password;
      case configJSON.NationalId: return languageData.DetailedInformationData.NationalId;
      case configJSON.CommercialRegistration: return languageData.DetailedInformationData.CommercialRegistration;
      case configJSON.BankLicenseNumber: return languageData.DetailedInformationData.BankLicenseNumber;
      case configJSON.AgencyRegistrationNumber: return languageData.DetailedInformationData.AgencyRegistrationNumber;
      case configJSON.AgencyLicenseNumber: return languageData.DetailedInformationData.AgencyLicenseNumber;
      case configJSON.RegistrationNumber: return languageData.DetailedInformationData.RegistrationNumber;
      case configJSON.ServiceLicenseNumber: return languageData.DetailedInformationData.ServiceLicenseNumber;      default: return "";
    }
  }

  setError(field: string, message: unknown = '') {
    const convertedField = this.getConvertedErrorField(field);
    const errorMsg = this.state.currentLanguage === configJSON.EN ? `${convertedField} is required` : `مطلوب ${convertedField}`;
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: message || `${errorMsg}`,
      },
    }));
  }
  resetError(field: string) {
    this.setState((prevState) => ({
      errors: {
        ...prevState.errors,
        [field]: '',
      },
    }));
  }

  isValidEmail(email: string) {
    return this.emailRegex.test(email);
  }

  isValidPassword() {
    return this.passwordRegex.test(this.state.basicInformation.password);
  }

  isAllFieldsAreFilled = () => {
    let error = false;
  
    if (this.showPersonalDetail() && this.isNullOrBlank(this.state.basicInformation.fullName, configJSON.FullName)) {
      this.setError(configJSON.FullName);
      error = true;
    }

    if (this.showBankDetail() && this.isNullOrBlank(this.state.basicInformation.bankName, configJSON.FullName)) {
      this.setError(configJSON.BankName);
      error = true;
    }

    if (this.showAgencyDetail() && this.isNullOrBlank(this.state.basicInformation.agencyName, configJSON.FullName)) {
      this.setError(configJSON.AgencyName);
      error = true;
    }

    if (this.showServiceProviderDetail() && this.isNullOrBlank(this.state.basicInformation.serviceProviderName, configJSON.FullName)) {
      this.setError(configJSON.ServiceProviderName);
      error = true;
    }

    if (this.isNullOrBlank(this.state.basicInformation.email, configJSON.Email)) {
      this.setError(configJSON.Email);
      error = true;
    }

    if (this.isNullOrBlank(this.state.basicInformation.password, configJSON.Password)) {
      this.setError(configJSON.Password);
      error = true;
    }

    if (this.isNullOrBlank(this.state.basicInformation.phone, configJSON.PhoneNumber)) {
      this.setError(configJSON.PhoneNumber);
      error = true;
    }    
    return error;
  }

  getConvertedErrorMsg = (field: string) => {
    const { currentLanguage } = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const convertedField = this.getConvertedErrorField(field);
    return isEnglish ? `${convertedField} is invalid` : `غير صالح ${convertedField}`
  }

  handleNextStep() {
    this.setState({ activeStep: this.state.activeStep + 1 });
  }

  handleBack() {
    if (this.state.activeStep === 3) {
      this.setState({ verificationCode: ["", "", "", "", "", ""]})
      this.resetError(configJSON.OTP);
    }
    this.setState({ activeStep: this.state.activeStep - 1 });
  }

  validateBasicInformation() {
    let error = false;
    error = this.isAllFieldsAreFilled();

    if(error){
      return;
    }
    if (!this.isValidEmail(this.state.basicInformation.email)) {
      const errorMsg = this.getConvertedErrorMsg(configJSON.Email);
      this.setError(configJSON.Email, errorMsg)
      error = true;
    }
    if (!this.isPhoneValid(this.state.basicInformation.phone)) {
      const errorMsg = this.getConvertedErrorMsg(configJSON.PhoneNumber);
      this.setError(configJSON.PhoneNumber, errorMsg)
      error = true;
    }
    if (!this.isValidPassword()) {
      const errorMsg = this.getConvertedErrorMsg(configJSON.Password);
      this.setError(configJSON.Password, errorMsg);
      error = true;
    }
    if (!error) {
      this.state.isEditBasicInfo ? this.updateBasicInformation() : this.submitData();
    }
  }

  validateOtpVerification() {
    let error = false;
    const { verificationCode } = this.state;
    if (verificationCode.join("").length < 6) {
      const errorMsg = this.getConvertedErrorMsg(configJSON.OTP);
      this.setError(configJSON.OTP, errorMsg)
      error = true;
    }

    if (!error) {
      this.verifyWithOtp();
    }
  }
  
  validateTermsAndConditions() {
    if (!this.state.terms) {
      this.setState({ termsError: true });
    } else {
      this.activateUserAccount();
    }
  }
  
  closeTermError() {
    this.setState({ termsError: false });
  }

  createSellerAccount() {
    const { activeStep } = this.state;
    switch (activeStep) {
      case 2:
        this.validateBasicInformation();
        break;
      case 3:
        this.validateOtpVerification();
        break;
      case 4:
        this.validateDetailInformation();
        break;
      case 5:
        this.showDebtPortfolio() && this.submitDebtPortfolio();
        this.showBusinessInterest() && this.submitBusinessInterest();
        break;
      case 6:
        this.validateTermsAndConditions()
        break;
      default:
        this.handleNextStep();
    }
  }

  isPhoneValid(phoneNumber: string) {
    return this.phoneRegex.test(phoneNumber);
  }

  canSubmitRegistration() {
    const { isEditBasicInfo, isEditDetailInfo, isEditDebtPortfolio, isEditBusinessInterest } = this.state;
    return !isEditBasicInfo && !isEditDetailInfo && !isEditDebtPortfolio && !isEditBusinessInterest
  } 

  signupButton = {
    onPress: () => this.createSellerAccount(),
  };

  bussinessDocProps = {
    onChangeText: (file: File) => {
      this.setState({ bussinessDoc: file });
      this.resetError(configJSON.BusinessRegistrationDocument)
    },
  };
  licenseDocProps = {
    onChangeText: (file: File) => {
      this.setState({ licenseDoc: file });
      this.resetError(configJSON.LicenseOrCertification)
    },
  };
  taxRegistrationDocProps = {
    onChangeText: (file: File) => {
      this.setState({ taxRegistrationDoc: file });
      this.resetError(configJSON.TaxRegistrationDocument)
    },
  };
  lastNameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ lastName: text });
      this.resetError(configJSON.LastName)
    },
  };

  usernameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ userName: text });
      this.resetError(configJSON.UserName)
    },
  };

  firstNameInputProps = {
    onChangeText: (text: string) => {
      this.setState({ firstName: text });
      this.resetError(configJSON.FirstName)
    },
  };

  txtInputEmailWebPrpos = {
    onChangeText: (text: string) => {
      this.setState({ email: text });
      this.resetError(configJSON.Email)
    },
  };

  phoneInputProps = {
    onChangeText: (text: string) => {
      this.setState({ phone: text });
      this.resetError(configJSON.PhoneNumber)
    },
  };

  setChecklistColor(password: string) {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumber = /\d/.test(password);
    const hasMinLength = password.length >= 8;
    
    this.setState({ passwordFieldChecklist: [
      hasUpperCase ? "#34D399" : "#94A3B8", 
      hasLowerCase ? "#34D399" : "#94A3B8", 
      hasNumber ? "#34D399" : "#94A3B8", 
      hasMinLength ? "#34D399" : "#94A3B8" ]})
  }

  basicInformationInputChange = {
    fullNameInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, fullName: text } });
      this.resetError(configJSON.FullName)
    },
  
    bankNameInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, bankName: text }});
      this.resetError(configJSON.BankName)
    },
  
    agencyNameInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, agencyName: text }});
      this.resetError(configJSON.AgencyName)
    },
  
    serviceProviderNameInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, serviceProviderName: text }});
      this.resetError(configJSON.ServiceProviderName)
    },

    emailInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, email: text } });
      this.resetError(configJSON.Email);
    },

    phoneInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, phone: text } });
      this.resetError(configJSON.PhoneNumber);
    },

    passwordInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, password: text } });
      this.resetError(configJSON.Password);
      this.setChecklistColor(text);
    },

    cityInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, city: text } });
      this.resetError(configJSON.City);
    },

    stateInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, state: text } });
      this.resetError(configJSON.State);
    },

    countryInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, country: text } });
      this.resetError(configJSON.Country);
    },

    zipInputProps: (text: string) => {
      this.setState({ basicInformation: { ...this.state.basicInformation, zip: text } });
      this.resetError(configJSON.Zip);
    }
  }

  debtPortfolioInputChange = {
    portfolioSizeChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, portfolioSize: text } });
      this.resetError('PortfolioSize');
    },
    accountCountChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, accountCount: text } });
      this.resetError('AccountCount');
    },
    averageBalanceChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, averageBalance: text } });
      this.resetError('AccountCount');
    },
    debtTypesChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, debtTypes: text } });
      this.resetError('DebtTypes');
    },
    debtAgeChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, debtAge: text } });
      this.resetError('DebtAge');
    },
    recoveryStatusChange: (text: string) => {
      this.setState({ debtPortfolio: { ...this.state.debtPortfolio, recoveryStatus: text } });
      this.resetError('RecoveryStatus');
    },
  }

  businessInterestInputChange = {
    portfolioSizeChange: (text: string) => {
      this.setState({ businessInterest: { ...this.state.businessInterest, portfolioSize: text } });
      this.resetError('PortfolioSize');
    },
    debtTypesChange: (text: string) => {
      this.setState({ businessInterest: { ...this.state.businessInterest, debtTypes: text } });
      this.resetError('DebtTypes');
    },
    debtAgeRangeChange: (text: string) => {
      this.setState({ businessInterest: { ...this.state.businessInterest, debtAgeRange: text } });
      this.resetError('DebtAgeRange');
    },
    geographicPreferenceChange: (text: string) => {
      this.setState({ businessInterest: { ...this.state.businessInterest, geographicPreference: text } });
      this.resetError('GeographicPreference');
    },
  }


  detailInformationInputChange = {
    dateChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, date: text } });
      this.resetError('Dob')
    },
    monthChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, month: text } });
      this.resetError('Dob')
    },
    yearChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, year: text } });
      this.resetError('Dob')
    },
    nationalIdChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, nationalId: text } });
      this.resetError('NationalId')
    },
    idProofChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, idProof: file } });
      this.resetError("IdProof")
    },
    addressProofChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, addressProof: file } });
      this.resetError("AddressProof")
    },
    commercialRegistrationChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, commercialRegistration: text } });
      this.resetError("CommercialRegistration")
    },
    bankLicenseNumberChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, bankLicenseNumber: text } });
      this.resetError("BankLicenseNumber")
    },
    commercialRegistrationDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, commercialRegistrationDocument: file } });
      this.resetError("CommercialRegistrationDocument")
    },
    bankLicenseDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, bankLicenseDocument: file } });
      this.resetError("BankLicenseDocument")
    },
    agencyRegistrationNumberChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, agencyRegistrationNumber: text } });
      this.resetError("AgencyRegistrationNumber");
    },
    agencyLicenseNumberChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, agencyLicenseNumber: text } });
      this.resetError("AgencyLicenseNumber");
    },
    agencyRegistrationDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, agencyRegistrationDocument: file } });
      this.resetError("AgencyRegistrationDocument");
    },
    agencyLicenseDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, agencyLicenseDocument: file } });
      this.resetError("AgencyLicenseDocument");
    },
    registrationNumberChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, registrationNumber: text } });
      this.resetError("RegistrationNumber")
    },      
    serviceLicenseNumberChange: (text: string) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, serviceLicenseNumber: text } });
      this.resetError("ServiceLicenseNumber")
    },
    registrationDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, registrationDocument: file } });
      this.resetError("RegistrationDocument")
    },
    serviceLicenseDocumentChange: (file: File) => {
      this.setState({ detailInformation: { ...this.state.detailInformation, serviceLicenseDocument: file } });
      this.resetError("ServiceLicenseDocument")
    }
  }

  handleFinalReviewCancel = () => {
    this.setState({ isEditBasicInfo: false, isEditBusinessInterest: false, isEditDetailInfo: false,
      isEditDebtPortfolio: false
     })
  }

  password = {
    onChangeText: (text: string) => {
      this.setState({ password: text });
      this.resetError(configJSON.Password)
    },
  };

  setTerms = () => {
    this.setState({ terms: !this.state.terms });
  };

  unifying = {
    onChangeText: (text: string) => {
      this.setState({ unifyingNumber: text });
      this.resetError(configJSON.UnifyingNumber)
    },
  };

  commercial = {
    onChangeText: (text: string) => {
      this.setState({ commercialRegNo: text });
      this.resetError(configJSON.CommercialRegistrationNumber)
    },
  };
  // Customizable Area End
}
