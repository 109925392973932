// Customizable Area Start
import React from "react";
import { Box, MenuItem, TextField, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

export interface DebtPortfolioInputChange {
  portfolioSizeChange: (text: string) => void;
  accountCountChange: (text: string) => void;
  averageBalanceChange: (text: string) => void;
  debtTypesChange: (text: string) => void;
  debtAgeChange: (text: string) => void;
  recoveryStatusChange: (text: string) => void;  
}
interface Props {
  debtPortfolioInputChange: DebtPortfolioInputChange;
  currentLanguage: string;
  renderTextInput: (
    id: string,
    label: string,
    value: string,
    onChange: (value: any) => void,
    type: string,
    error: string,
    endAdornment?: boolean
  ) => JSX.Element;
  classes: any;
  errors: {
    PortfolioSize: string;
    AccountCount: string;
    AverageBalance: string;
    DebtTypes: string;
    DebtAge: string;
    CurrentRecoveryStatus: string;
  };
  debtPortfolio: {
    portfolioSize: string;
    accountCount: string;
    averageBalance: string;
    debtTypes: string;
    debtAge: string;
    recoveryStatus: string;
  };
}

const CustomBox = styled(Box)({
  "@media (max-width: 850px)": {
    "& div": {
      width: "100%"
    },
    "& .MuiBox-root": {
      width: "100%"
    },
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "340px !important"
    }
  },
  "@media (max-width: 767px)": {
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100% !important"
    }
  }
});

const MainBox = styled(Box)({
  "@media (max-width: 850px)": {
    width: "100%"
  }
});

const webStyle = {
  mainBox: {
    "@media (min-width: 1920px)": {
      paddingTop: 120,
      marginBottom: 80
    }
  }
}

class DebtPortfolioWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

  render() {
    const languageData = languageTranslationData[this.props.currentLanguage];
    const { classes, renderTextInput, errors, debtPortfolio, debtPortfolioInputChange
      } = this.props;

    return(
      <MainBox sx={webStyle.mainBox as CSSProperties} mb={10} flexDirection="column" display="flex" alignItems="center">
        <Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.DebtPortfolio}</Text>
        <Box mt={2} mb={3}>
          <Text align="center" size="sm" family="inter" variant="grey" weight="minBold">{languageData.DebtPortfolioDesc}</Text>
        </Box>

        <CustomBox sx={classes.form}>
          {renderTextInput("portfolioSize", languageData.DebtPortfolioData.PortfolioSize, debtPortfolio.portfolioSize, debtPortfolioInputChange.portfolioSizeChange, "text", errors['PortfolioSize'])}
          {renderTextInput("accountCount", languageData.DebtPortfolioData.AccountCount, debtPortfolio.accountCount, debtPortfolioInputChange.accountCountChange, "text", errors['AccountCount'])}
        </CustomBox>
        <CustomBox sx={classes.form} alignItems="end">
          {renderTextInput("averageBalance", languageData.DebtPortfolioData.AverageBalance, debtPortfolio.averageBalance, debtPortfolioInputChange.averageBalanceChange, "text", errors['AverageBalance'])}
          <Box mt={2} display="flex" flexDirection="column" gridGap="10px">
            <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.TypesDebt}</Text>
              <TextField
                data-test-id="typesdebt"
                id="typesdebt"
                fullWidth
                select
                size="small"
                value={debtPortfolio.debtTypes}
                variant="outlined"
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => debtPortfolioInputChange.debtTypesChange(event.target.value)}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: debtPortfolio.debtTypes !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.typesDebt}</Text>,
                }} 
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
              >
                <MenuItem key="type1" value={1}>Type 1</MenuItem>
                <MenuItem key="type2" value={2}>Type 2</MenuItem>
                <MenuItem key="type3" value={3}>Type 3</MenuItem>
              </TextField>
          </Box>
        </CustomBox>
        <CustomBox sx={classes.form} alignItems="end">
          {renderTextInput("ageDebt", languageData.DebtPortfolioData.AgeDebt, debtPortfolio.debtAge, debtPortfolioInputChange.debtAgeChange, "text", errors['DebtAge'])}
          <Box mt={2} display="flex" flexDirection="column" gridGap="10px">
            <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.CurrentRecoveryStatus}</Text>
              <TextField
                data-test-id="recoveryStatus"
                id="recoveryStatus"
                fullWidth
                select
                size="small"
                variant="outlined"
                value={debtPortfolio.recoveryStatus}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => debtPortfolioInputChange.recoveryStatusChange(event.target.value)}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: debtPortfolio.recoveryStatus !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.currentRecoveryStatus}</Text>,
                }} 
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
              >
                <MenuItem key="recovery1" value={1}>Recovery 1</MenuItem>
                <MenuItem key="recovery2" value={2}>Recovery 2</MenuItem>
                <MenuItem key="recovery3" value={3}>Recovery 3</MenuItem>
              </TextField>
          </Box>
        </CustomBox>
      </MainBox>
    )
  }
}

export default DebtPortfolioWeb;