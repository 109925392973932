import React, { CSSProperties, Component } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { bgImage } from "../../blocks/email-account-login/src/assets";
import Image from "../src/design-system/Image/Image";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from '@material-ui/core/styles';

export const LeftSection: React.FC = ({ children }: any) => {
    return (
      <Grid item xs={12} sm={6} md={6}>
        {children}
      </Grid>
    )
}

export const RightSection: React.FC = () => {
    return (
      <Grid item xs={12} sm={6} md={6}>
        <Image style={{ width: "100%" }} src={bgImage} alt="userImg" />
      </Grid>
    )
}

export const LoginFlow: React.FC = ({ children }: any) => {
  const classe = useStyles();
    return (
      <Paper elevation={0} style={classes.paper}>
        <Box sx={classes.containerAuto}>
          <Grid container className={classe.gridConatiner}>
            <LeftSection>
                {children}
            </LeftSection>
            <RightSection />
          </Grid>
        </Box>
      </Paper>
    )
}

const useStyles = makeStyles({
  gridConatiner:{
    borderRadius: '10px', overflow: 'auto' ,
    alignItems: "center",
    "@media (max-width: 990px)": {
      alignItems: "flex-start"
    }
  }
});

const classes = {
containerAuto: {
    margin: '0 auto',
    "@media (max-width: 600px)": {
        padding: "40px 20px"
    }
},
labelText: {
    fontSize: "12px",
    margin: "0px",
    color: "#334155",
    fontWeight: 700,
},
containerLogin: {
    height: '100%',
    boxSizing: 'border-box',
    padding: '44px 80px',
    "@media (max-width: 960px)": {
        padding: "40px 20px"
    },
    "@media (max-width: 600px)": {
        padding: "40px 20px",
    },
},
paper: {
    backgroundColor: "#FFFFFF",
},
linkText: {
    cursor: 'pointer',
    marginLeft: 5,
    color: '#1E#A8A',
    "&:hover": {
        textDecoration: 'underline'
    },
  }
}