import React, { CSSProperties } from "react";
import Carousel from "react-material-ui-carousel";

import {
  Container,
  Button,
  Typography,
  // Customizable Area Start
  Grid,
  Card,
  CardContent,
  CardActions,
  Box,
  Divider,
  IconButton,
  // styled,

  // Customizable Area End
} from "@material-ui/core";

// Customizable Area Start
import { createTheme, ThemeProvider, styled } from "@material-ui/core/styles";
import TextInput from "../../../components/src/design-system/TextInput";
import { debtImage, aboutusImage, visionImage, ringImage, efficiencyImage,
  transparencyImage, supportImage, person1, person2, person3, bgImageRight
 } from "./assets";
import parse from 'html-react-parser';
import DiscoverCard from "../../../components/src/design-system/Card/DiscoverCard";
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';

const benefitsTranslationData = require('../../languagesupport/src/LanguageData/benefits.json');
const homeTranslationData = require('../../languagesupport/src/LanguageData/homePage.json');

const theme = createTheme({
  palette: {
    primary: {
      main: "#fff",
      contrastText: "#fff",
    },
  },
  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const GridBox = styled(Grid)({
  '& .sectionOneTitle': {
    color: '#000',
    fontStyle: 'normal',
    fontWeight: 600,
    lineHeight: '52px',
    fontSize: '36px',
    marginBottom: '24px',

    '@media (max-width: 1200px)': {
      fontSize: '25px',
      lineHeight: '30.205px',
    }
  },
  '& .img-banner': {
    '@media (max-width: 1200px)': {
      width: '80%',
    }
  }
})

const TopLeftGrid = styled(Grid)({
  '@media (max-width: 840px)': {
    flexBasis: "100%",
    maxWidth: "100%"
  }
});

const GridBox1 = styled(Grid)({
  '& .MuiGrid-root': {
    '@media (max-width: 1200px)': {
      width: 'calc(100% + 0px)',
    }
  },
  '@media (max-width: 1200px)': {
    width: 'calc(100% + 0px)',
    paddingRight: '15px',
  }
})

const SectionMain = styled(Container)({
  padding: '90px 40px',
  background: `url(${bgImageRight}) no-repeat top right`,
  backgroundSize: "auto 100%",
  "@media (max-width: 960px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
  "@media (min-width: 1440px)": {
    backgroundSize: "auto 115%",
  },
  '& .MuiInputBase-input-root': {
    padding: 12
  },
  '& .MuiInputBase-input:focus' : {
    outline: 'solid'
  }
});

const Section1 = styled(Container)({
  padding: '90px 40px',
  "@media (max-width: 960px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
})

const Image1 = styled(Grid)({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  "@media (max-width: 960px)": {
    alignItems: "center",
  },
  '@media (max-width: 840px)': {
    flexBasis: "100%",
    maxWidth: "100%"
  },
  "@media (max-width: 600px)": {
    alignItems: "center",
  },
})

const CardBox = styled(Box)({
  "@media (max-width: 1280px)": {
    paddingLeft: "40px",
    paddingRight: "40px"
  },
  "@media (max-width: 960px)": {
    paddingLeft: "20px",
    paddingRight: "20px"
  },
  "@media (max-width: 767px)": {
    paddingLeft: "0px",
    paddingRight: "0px"
  },
  "& .MuiGrid-root .MuiGrid-item": {
    "@media (max-width: 960px)": {
      flexBasis: "50%",
      maxWidth: "50%"
    },
    "@media (max-width: 840px)": {
      flexBasis: "100%",
      maxWidth: "100%",
      "& .MuiPaper-root": {
        textAlign: "center"
      }
    }
  },
  "& .MuiGrid-root": {
    "&[class*='gridRightToLeft']": {
      "@media (max-width: 991px)": {
        flexDirection: "row-reverse"
      },
      "@media (max-width: 840px)": {
        flexDirection: "column"
      }
    }
  }
});

const TopSearchButtonBox = styled(Box)({
  marginTop: '32px',
    display: "flex",
    gap: "20px",
    flexWrap: "nowrap",
    "@media (max-width: 840px)": {
      flexWrap: "wrap"
    }
});

type TextAlign = "left" | "right" | "center" | "justify";

// Customizable Area End

import LandingPageController, { Props, configJSON } from "./LandingPageController.web";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";


class LandingPage extends LandingPageController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // const classes = useStyles();
    // Customizable Area End
  }

  // Customizable Area Start
  testimonialBlock() {
    return (
      <Grid container xs={12} sm={12} spacing={2}>
        <Grid item xs={12} sm={4}>
          <Card style={webStyle.testimonycardStyle}>
              <CardContent style={{ padding: "0px" }}>
                <Typography
                  variant="body2"
                  style={webStyle.testimonycardDetail}
                >
                  An incredible platform that revolutionized how I manage my debts. From listing to settlement, Jeddah House of Commerce ensured a seamless process every step of the way. Highly recommend!
                </Typography>
                <Divider />
                <Grid container style={webStyle.userDetails}>
                  <div style={webStyle.testimonyImage}>
                    <img width={64} height={64} src={person1} />
                  </div>
                  <div style={webStyle.testimonyName as CSSProperties}>
                    <Typography component="span" style={webStyle.userName}>
                      John Smith
                    </Typography>
                    <Typography component="span" style={webStyle.userPost}>
                      Financial Analys
                    </Typography>
                  </div>
            </Grid>
          </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={webStyle.testimonycardStyle}>
            <CardContent style={{ padding: "0px" }}>
              <Typography
                variant="body2"
                style={webStyle.testimonycardDetail}
              >
                Jeddah House of Commerce exceeded my expectations. Their platform is user-friendly, and their support team is incredibly helpful. A game-changer for debt management!
              </Typography>
              <Divider />
              <Grid container style={webStyle.userDetails}>
                <div style={webStyle.testimonyImage}>
                  <img width={64} height={64} src={person2} alt="Clarity" />
                </div>
                <div style={webStyle.testimonyName as CSSProperties}>
                  <Typography component="span" style={webStyle.userName}>
                    Sarah Johnson
                  </Typography>
                  <Typography component="span" style={webStyle.userPost}>
                    Debt Owner
                  </Typography>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
        <Grid item xs={12} sm={4}>
          <Card style={webStyle.testimonycardStyle}>
            <CardContent style={{ padding: "0px" }}>
              <Typography
                variant="body2"
                style={webStyle.testimonycardDetail}
              >
                I've been using Jeddah House of Commerce for a few months now, and I'm impressed by their efficiency and professionalism. Their platform made it easy for me to sell my debts and connect with buyers.
              </Typography>
              <Divider />
              <Grid container style={webStyle.userDetails}>
                <div style={webStyle.testimonyImage}>
                  <img width={64} height={64} src={person3} alt="Clarity" />
                </div>
                <div style={webStyle.testimonyName as CSSProperties}>
                  <Typography component="span" style={webStyle.userName}>
                    David Rodriguez
                  </Typography>
                  <Typography component="span" style={webStyle.userPost}>
                    Debt Buyer
                  </Typography>
                </div>
              </Grid>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    )
  }
  // Customizable Area End

  render() {
    const { data, currentLanguage } = this.state;
    const isEnglish = currentLanguage === configJSON.EN;
    const cardData = [
      {
        darkTheme: false,
        alignLeft: true,
      },
      {
        darkTheme: false,
        alignLeft: false,
      },
    ];
    const benefitImages = [efficiencyImage, transparencyImage, supportImage];
    const homeData = homeTranslationData[currentLanguage];
    const cardImages = [aboutusImage, visionImage];
    const section1title = homeData.Section1.title.split("%");

    return (
      // Customizable Area Start
      <ThemeProvider theme={theme}>
        {<SectionMain maxWidth={false} style={webStyle.section1}>
            {<GridBox container spacing={2} style={webStyle.firstRow}>
              <TopLeftGrid
                item
                xs={12}
                sm={7}
                lg={7}
                justifyContent="center"
                direction="column"
                style={webStyle.sectionOne}
              >
                <Typography variant="h4" className="sectionOneTitle">
                  {section1title[0]}<span style={webStyle.highlightText}>{section1title[1]}</span>{section1title[2]}
                </Typography>
                <Typography variant="body1" style={webStyle.sectionOneDetail}>
                  {parse(`${homeData.Section1.description}`)}
                </Typography>
                <TopSearchButtonBox alignItems="center">
                  <Box flexGrow={1}>
                    <TextInput placeholder="name@company.com" />
                  </Box>
                  <Box>
                    <Button data-testid="discover-btn" variant="contained" style={webStyle.buttonStyles as CSSProperties}>{homeData.Section1.buttonText}</Button>
                  </Box>
                </TopSearchButtonBox>
              </TopLeftGrid>
              <Image1
                item
                xs={12}
                sm={5}
                lg={5}
              >
                <img className="img-banner" style={webStyle.imageOneimage} src={debtImage} alt="Frame" />
              </Image1>
            </GridBox>}
          </SectionMain>
        }
        {cardData.map((card, cIndex: number) => (
          <CardBox key={cIndex}>
            <ImageTextCard
              topHeading={homeData[`Section${cIndex+2}`].heading}
              key={homeData[`Section${cIndex+2}`].title}
              image={cardImages[cIndex]}
              heading={homeData[`Section${cIndex+2}`].title}
              description={homeData[`Section${cIndex+2}`].description}
              darkTheme={card.darkTheme}
              alignLeft={card.alignLeft}
            />
          </CardBox>
        ))}
        <Section1 maxWidth={false} style={webStyle.section2}>
          <GridBox1 container spacing={3} style={webStyle.gridContainer}>
            <Grid item xs={12} sm={12} style={webStyle.benefitHeading as CSSProperties}>
              <Typography align="center" variant="body1" style={webStyle.benefitCaption}>{benefitsTranslationData[currentLanguage].heading}</Typography>
              <Typography align="center" variant="h6" style={webStyle.benefitTitle}>{benefitsTranslationData[currentLanguage].title}</Typography>
              <Typography align="center" variant="caption" style={webStyle.benefitDescription}>
                {benefitsTranslationData[currentLanguage].description.split("<br/>").map((text: string) =>
                  <>  
                    {text} <br />
                  </>
                )}</Typography>
            </Grid>
            {benefitsTranslationData[currentLanguage].Benefits.map((benefit: { title: string, description: string}, bIndex: number) => {
              return(<Grid item xs={12} sm={4}>
                <Card style={webStyle.cardStyle}>
                  <div style={{ ...webStyle.imageCenter, marginBottom: "30px" }}>
                    <img width={80} height={80} src={benefitImages[bIndex]} alt={benefit.title} />
                  </div>

                  <CardContent style={{ padding: "0px" }}>
                    <Typography
                      style={webStyle.benefitListTitle}
                      align={"center"}
                      variant="h6"
                    >
                      {benefit.title}
                    </Typography>
                    <Typography
                      align={"center"}
                      variant="body2"
                      style={webStyle.benefitListDetail}
                    >
                      {benefit.description}
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>)
            } )}
          </GridBox1>
        </Section1>
        {data[4] && 
        <Section1 maxWidth={false} style={webStyle.section1}>
          <GridBox container spacing={2} style={webStyle.firstRow}>
            <Grid item xs={12} sm={12} style={webStyle.benefitHeading as CSSProperties}>
              <Typography align="center" variant="body1" style={webStyle.benefitCaption}>{homeData.testimonials.heading}</Typography>
              <Typography align="center" variant="h6" style={webStyle.benefitTitle}>{homeData.testimonials.title}</Typography>
              <Typography align="center" variant="caption" style={webStyle.benefitDescription}>{homeData.testimonials.subtitle}</Typography>
            </Grid>
            <Carousel autoPlay={false} indicators={false} navButtonsAlwaysInvisible={true}>
              {this.testimonialBlock()}
              {this.testimonialBlock()}
           </Carousel>
            <Grid item>
              <IconButton
                style={{ backgroundColor: '#F1F5F9', padding: 10, borderRadius: 8, width: 44, height: 44, color: '#1E3A8A' }}
                >
                <ArrowBackIos />
              </IconButton>

              <IconButton
                style={{ backgroundColor: '#1E3A8A', padding: 10, borderRadius: 8, width: 44, height: 44, color: '#fff' }}
              >
                <ArrowForwardIos />
              </IconButton>
            </Grid>
          </GridBox>
        </Section1>}
        <DiscoverCard />
      </ThemeProvider>
      // Customizable Area End
    );
  }
}

export default LandingPage;
// Customizable Area Start

const webStyle = {
  whiteText: {
    color: "white",
    marginTop: 5,
  },
  highlightText: {
    backgroundColor: "#DBEAFE", 
    color: "#1D4ED8", 
    borderRadius: 16, 
    padding: "2px 5px",
    whiteSpace: "nowrap"
  } as React.CSSProperties,
  section4Wrapper: {
    maxWidth: 'none'
  },
  debtContainer: {
    backgroundColor: '#1E293B',
    backgroundImage: `url(${ringImage})`,
    backgroundSize: 'cover',
    borderRadius: 32,
    padding: 80
  },
  section6Title: {
    color: "#FFFFFF",
    fontSize: 30,
    fontWeight: 600,
    lineHeight: "44px"
  },
  section6Detail: {
    color: "#FFFFFF",
    fontSize: 16,
    fontWeight: 400,
    lineHeight: "24px"
  },
  sectionOne: {
    display: "flex",
  },
  gridContainer: {
    maxWidth: "1440px",
    margin: "0 auto",
    width: "100%",
    alignItems: "center",
    background: "#EFF6FF"
  },
  sectionContain: {
    justifyContent: "center",
    maxWidth: "none",
    backgroundColor: "#EFF6FF",
  },
  imageOneimage: {
    width: "90%",
    maxWidth: "488px"
  },
  containerStyle: {
    paddingLeft: 0,
    paddingRight: 0,
    maxWidth: "100%"
  },

  mainWrapper: {
    display: "flex",
    fontFamily: "Roboto-Medium",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "30px",
    background: "#fff",
  },
  inputStyle: {
    borderBottom: "1px solid rgba(0, 0, 0, 0.6)",
    width: "100%",
    height: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  buttonStyle: {
    width: "100%",
    height: "45px",
    marginTop: "40px",
    border: "none",
    backgroundColor: "rgb(98, 0, 238)",
  },
  button: {
    marginTop: "20px",
    width: "max-content",
  },
  arrow: {
    height: 20,
    width: 20,
    resizeMode: "contain",
  },
  section1: {
    height: "auto",
    justifyContent: "center",
    maxWidth: "none",
    alignItems: 'center',
    display: 'flex',
  },
  imageCenter: {
    textAlign: "center" as TextAlign,
  },
  imagesection4: {
    width: "90%",
    maxWidth: "800px",
    padding: "35px 0px"
  },
  section2: {
    justifyContent: "center",
    maxWidth: "none",
    background: "#EFF6FF",
  },
  gridStyle: {
    backgroundColor: "#f0f4f8",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  testimonycardStyle: {
    backgroundColor: 'none',
    border: '1px solid #F1F5F9',
    borderRadius: '24px',
    padding: 34,
    boxShadow: "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03)"

  },
  testimonycardDetail: {
    fontSize: 16,
    fontWeight: 400,
    height: 140,
    overflow: "auto",
    "@media (max-width: 960px)": {
      height: "auto"
    }
  },
  testimonyImage: {
    borderRadius: '50%',
    width: '64px',
    height: '64px',
    marginRight: 10
  },
  testimonyName: {
    display: 'flex',
    flexDirection: "column",
  },
  userDetails: {
    display: 'flex',
    alignItems: 'center',
    margin: 10
  },
  userName: {
    fontSize: 18,
    fontWeight: 600
  },
  userPost: {
    fontSize: 14,
    fontWeight: 400
  },
  cardStyle: {
    boxShadow: "none",
    backgroundColor: "#EFF6FF",
    padding: 20,
    display: "grid",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "10px"
  },
  buttonStyles: {
    backgroundColor: "#1E3A8A",
    color: "#fff",
    alignSelf: "center",
    padding: "13px 30px",
    textTransform: "none",
    fontSize: "16px",
    whiteSpace: "nowrap"
  },
  cardActionsStyle: {
    display: "flex",
    justifyContent: "center",
  },
  section3: {
    padding: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  section4: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  secFoure: {
    justifyContent: "center",
    maxWidth: "none",
    background: "#F1F5F9",
  },
  secCenter: {
    alignItems: "center",
    justifyContent: "center",
  },
  gape: {
    marginTop: 10,
  },

  sectionOneDetail: {
    color: "#0F172A",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    width: '100%',
    lineHeight: "26px"
  },

  sectionOneBtn: {
    borderRadius: "8px",
    background: "#FFF",
    boxShadow:
      "0px 8px 32px 0px rgba(0, 0, 0, 0.06), 0px 4px 8px 0px rgba(0, 0, 0, 0.03), 0px 25px 50px 0px rgba(0, 0, 0, 0.09)",
    color: "#0F172A",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 700,
    textTransform: "capitalize" as const,
    lineHeight: "24px",
    width: "320px",
    marginBottom: "30px"
  },

  section2CardTitle: {
    color: "#1E293B",
    textAlign: "center" as const,
    fontSize: "24px",
    fontStyle: "normal",
    fontWeight: 700,
    lineHeight: "24px",
    fontFamily: "inter",
    marginBottom: "12px"
  },
  section2CardDetail: {
    color: "#334155",
    textAlign: "center" as const,
    fontSize: "16px",
    fontWeight: 700,
    lineHeight: "21.5px",
    fontFamily: "inter"
  },
  section2CardBtn: {
    display: "inline-flex",
    height: "44px",
    padding: "10px 16px",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    background: "#1E3A8A",
    color: "#fff",
    textTransform: 'capitalize' as const
  },
  section3Title: {
    color: "#FFF",
    fontSize: "24px",
    fontStyle: "normal",
    fontFamily: "inter",
    fontWeight: 700,
  },
  section3Detail: {
    color: "#FFF",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
    marginTop: "16px",
    fontFamily: "inter",
    width: '94%',
    marginBottom: "20px"
  },
  section4Title: {
    color: "#1E293B",
    textAlign: "center" as const,
    fontSize: "30px",
    fontStyle: "normal",
    fontWeight: 700,
    marginBottom: "10px",
  },
  section4Subtitle: {
    color: "#1E293B",
    textAlign: "center" as const,
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  section4Detail: {
    color: "var(--neutrals-cool-gray-800, #1E293B)",
    textAlign: "center" as const,
    fontSize: "17px",
    fontStyle: "normal",
    fontWeight: 400,
  },
  section4Row: {
    margin: "0 auto",
  },
  firstRow: {
    maxWidth: "1200px",
    margin: "0px auto",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  sectionOneTitle: {
    color: "#000",
    fontSize: "36px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "52px",
    marginBottom: '24px'
  },
  benefitHeading: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
  },
  benefitCaption: {
    fontSize: '18px',
    color: '#2563EB'
  },
  benefitTitle: {
    fontWeight: 600,
    fontSize: '30px',
    lineHeight: '44px'
  },
  benefitDescription: {
    color: "#64748B",
    fontSize: '18px',
    lineHeight: '26px'
  },
  benefitListTitle: {
    fontSize: 24,
    fontWeight: 600
  },
  benefitListDetail: {
    fontSize: 14,
    fontWeight: 400
  },
  '@media (max-width: 1200px)': {
    sectionOneTitle: {
      fontSize: "26px",
      lineHeight: "35.205px",
      color: 'red',
      background: 'red',
      marginBottom: '32px'
    }
  }
};
// Customizable Area End
