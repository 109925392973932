import React from "react";
import { Box, MenuItem, TextField, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

export interface BusinessInterestInputChange {
  portfolioSizeChange: (text: string) => void;
  debtTypesChange: (text: string) => void;
  debtAgeRangeChange: (text: string) => void;
  geographicPreferenceChange: (text: string) => void;  
}

interface Props {
  businessInterestInputChange: BusinessInterestInputChange;
  currentLanguage: string;
  renderTextInput: (
    id: string,
    label: string,
    value: string,
    onChange: (value: any) => void,
    type: string,
    error: string,
    endAdornment?: boolean
  ) => JSX.Element;
  classes: any;
  errors: {
    DebtTypes: string;
    PortfolioSize: string;
    DebtAgeRange: string;
    GeographicPreference: string;
  };
  businessInterest: {
    debtTypes: string;
    portfolioSize: string;
    debtAgeRange: string;
    geographicPreference: string;
  };
}

const MainBox = styled(Box)({
  "@media (max-width: 850px)": {
    width: "100%"
  }
});

const CustomBox = styled(Box)({
  "@media (max-width: 850px)": {
    "& div": {
      width: "100%"
    },
    "& .MuiBox-root": {
      width: "100%"
    },
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "340px !important"
    }
  },
  "@media (max-width: 767px)": {
    "& .MuiFormControl-root.MuiTextField-root": {
      width: "100% !important"
    }
  }
});

const FieldBox = styled(Box)({
  "@media (max-width: 800px)": {
    marginTop: "20px"
  }
});

const webStyle = {
  mainBox: {
    "@media (min-width: 1920px)": {
      paddingTop: 120,
      marginBottom: 80
    }
  }
}

class BusinessInterestWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

  render() {
    const languageData = languageTranslationData[this.props.currentLanguage];
    const { classes, renderTextInput, errors, businessInterest, businessInterestInputChange
      } = this.props;

    return(
      <MainBox mb={12} sx={webStyle.mainBox as CSSProperties} flexDirection="column" display="flex" alignItems="center">
        <Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.BusinessInterests}</Text>
        <Box mt={2} mb={4}>
          <Text align="center" size="sm" family="inter" variant="grey" weight="minBold">{languageData.BusinessInterestsDesc}</Text>
        </Box>

        <CustomBox sx={classes.form} alignItems="end">
        <Box display="flex" flexDirection="column" gridGap="10px">
            <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.TypesDebt}</Text>
              <TextField
                data-test-id="typesdebt"
                id="typesdebt"
                fullWidth
                select
                size="small"
                variant="outlined"
                value={businessInterest.debtTypes}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => businessInterestInputChange.debtTypesChange(event?.target.value)}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: businessInterest.debtTypes !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.typesDebt}</Text>,
                }} 

                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
              >
                <MenuItem key="type1" value="type1">Type 1</MenuItem>
                <MenuItem key="type2" value="type2">Type 2</MenuItem>
                <MenuItem key="type3" value="type2">Type 3</MenuItem>
              </TextField>
          </Box>
          {renderTextInput("portfolioDebtSize", languageData.BusinessInterestsData.PortfolioSize, businessInterest.portfolioSize, businessInterestInputChange.portfolioSizeChange, "text", errors['PortfolioSize'])}
        </CustomBox>
        <CustomBox sx={classes.form} alignItems="end">
          {renderTextInput("debtAgeRange", languageData.BusinessInterestsData.PreferredDebtAgeRange, businessInterest.debtAgeRange, businessInterestInputChange.debtAgeRangeChange, "text", errors['DebtAgeRange'])}
          <FieldBox display="flex" flexDirection="column" gridGap="10px">
            <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.BusinessInterestsData.GeographicPreferences}</Text>
              <TextField
                data-test-id="geographicPreference"
                id="geographicPreference"
                fullWidth
                select
                size="small"
                variant="outlined"
                value={businessInterest.geographicPreference}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => businessInterestInputChange.geographicPreferenceChange(event?.target.value)}
                InputProps={{
                  style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                }}
                SelectProps={{
                  displayEmpty: true,
                  renderValue: businessInterest.geographicPreference !== '' ? undefined : () => 
                  <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.geographicPreferences}</Text>,
                }} 
              >
                <MenuItem key="geography1" value="geography1">Geographic 1</MenuItem>
                <MenuItem key="geography2" value="geography2">Geographic 2</MenuItem>
                <MenuItem key="geography3" value="geography3">Geographic 3</MenuItem>
              </TextField>
          </FieldBox>
        </CustomBox>
      </MainBox>
    )
  }
}

export default BusinessInterestWeb;