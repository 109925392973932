import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { clipIcon, documentIcon } from "./assets";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')

interface UserDataResponse {
    data?: UserData;
    errors?: string|boolean;
}

interface PassCheckArrayItem {
    valid: boolean;
    value: string;
};

interface UserData {
    
      id: string;
      type: string;
      attributes: {
        activated: boolean;
        full_name: string;
        date_of_birth: string;
        license_number: string;
        first_name: string  | null;
        last_name: string  | null;
        full_phone_number: string  | null;
        country_code: string | null;
        email: string  | null;
        phone_number: string | null;
        gender: string | null;
        type: string  | null;
        user_type: string  | null;
        user_name: string  | null;
        address: string | null;
        city: string | null;
        state: string | null;
        country: string | null;
        zipcode: number | null;
        business_name: string | null;
        tax_number: string | null;
        created_at: string  | null;
        updated_at: string  | null;
        device_id: string | null;
        unique_auth_id: string  | null;
        kyc_name: string | null;
        kyc_number: string | null;
        kyc_verified: boolean | null;
        kyc_document:string | null,
        profile_image: string | null;
        images: {
          tax_registration: IDocumentInfo,
          license_certification:IDocumentInfo,
          business_registration:IDocumentInfo,
          other_documents:IDocumentInfo,
          id_proof_image: IDocumentInfo;
          address_proof_image: IDocumentInfo;
        };
      };
    }

    interface IDocumentInfo {
        file_name: string | null
        url: string | null;
        size: string | null;
    }

export interface ValidResponseType {
    data: UserData[];
    message: string;
};

export interface ErrorPayloadType {
    key: string;
    token: string;
};
  
export interface InvalidResponseType {
    errors: Array<ErrorPayloadType>;
};
  
// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes:ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    response: UserData['attributes'],
    profile: {
        label: string,
        id: keyof UserData['attributes'],
        error: string,
        value: UserData['attributes'][keyof UserData['attributes']],
        type: string,
        regex: RegExp
    }[],
    profileConfirmPassword: {
        label: string,
        id: string,
        error: string,
        value: string,
        type: string,
        regex: RegExp
    }
    business: {
        label: string,
        id: 'business_name'|'tax_number',
        error: string,
        value: string | null,
        type: string,
        regex: RegExp
    }[],
    businessDocument: {
        label: string,
        id: "business_registration"|"tax_registration"|"license_certification"|"other_documents",
        error: string,
        file: File | null,
        icon: string
    }[],
    anchorEl: null | HTMLElement,
    kycVerification: {
        label: string,
        id: keyof UserData['attributes'],
        error: string,
        value: UserData['attributes'][keyof UserData['attributes']],
        type: string,
        regex: RegExp
    }[],
    edit: "profile" | "business" | "kyc" | null,
    profilePicture: string|null,
    newBusinessRegistration: File | null,
    newTaxRegistration: File | null,
    newLicenseCertification: File | null,
    currentLanguage: 'ar' | 'en';
    editBasic: boolean;
    editDetail: boolean;
    commonHide: boolean;
    showUploadMenu: boolean;
    showPassword: boolean;
    showPopover: boolean;
    pageHeading: string;
    errorCheck: {
        fullNameError: string | undefined;
        emailError: string | undefined;
        phoneNumberError: string | undefined;
        passwordError: string | undefined;
        nationalIDError: string | undefined;
      };
    checkSave: boolean;
    breadScrumArray: string[],
    fullName: string;
    password: string;
    passCheckArray: PassCheckArrayItem[];
    dateDay: string;
    dateMonth: string;
    dateYear: string;
    nationalId: string;
    formattedPhoneNumber: string;
    idProof: File | null;
    addressProof: File | null;
    showModal: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class ProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchProfileCallId: string= "";
    saveProfileCallId: string = "";
    removeProfileCallId: string = "";
    updateDetailCallId: string = "";
    deleteAccountCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage: 'en',
            response: {
                activated: false,
                full_name: "",
                date_of_birth: "",
                license_number: "",
                first_name: null,
                last_name: null,
                full_phone_number: null,
                country_code: null,
                email: null,
                phone_number: null,
                gender: null,
                type: null,
                user_type: null,
                user_name: null,
                address: null,
                city: null,
                state: null,
                country: null,
                zipcode: null,
                business_name: null,
                tax_number: null,
                created_at: null,
                updated_at: null,
                device_id: null,
                unique_auth_id: null,
                profile_image: null,
                kyc_name: null,
                kyc_number: null,
                kyc_verified: false,
                kyc_document:null,
                images: {
                    tax_registration: {
                        file_name: null,
                        url: null,
                        size: null
                    },
                    license_certification:{
                        file_name: null,
                        url: null,
                        size: null
                    },
                    business_registration:{
                        file_name: null,
                        url: null,
                        size: null
                    },
                    other_documents:{
                        file_name: null,
                        url: null,
                        size: null
                    },
                    id_proof_image: {
                        file_name: null,
                        url: null,
                        size: null
                    },
                    address_proof_image: {
                        file_name: null,
                        url: null,
                        size: null
                    }
                }
            },
            profilePicture: null,
            profile: profileFields('en'),
            profileConfirmPassword: {
                "label": "Confirm Password",
                "id": "confirmpassword",
                "value": "",
                "error": "",
                "type": "password",
                "regex": /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{6,}$/
            },
            business: businessUserProfileFields('en'),
            businessDocument: documentFields('en'),
            anchorEl: null,
            kycVerification: kycUserProfileFields('en'),
            edit: null,
            newBusinessRegistration: null,
            newLicenseCertification: null,
            newTaxRegistration: null,
            editBasic: false,
            editDetail: false,
            commonHide: false,
            showUploadMenu: false,
            showPassword: false,
            showPopover: false,
            pageHeading: "Account Details",
            errorCheck: {
                fullNameError: "",
                emailError: "",
                phoneNumberError: "",
                passwordError: "",
                nationalIDError: "",
            },
            checkSave: false,
            breadScrumArray: [],
            fullName: "",
            password: "",
            passCheckArray: [
                { valid: false, value: "At least one capital letter" },
                { valid: false, value: "At least one lowercase letter" },
                { valid: false, value: "At least one number" },
                { valid: false, value: "Minimum character length is 8 characters" },
            ],
            dateDay: "",
            dateMonth: "",
            dateYear: "",
            nationalId: "Your National ID",
            formattedPhoneNumber:"",
            idProof: null,
            addressProof: null,
            showModal: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.fetchProfileCallId) {
                    if (!responseJson.errors) {
                        this.handleApiResponse(responseJson.data.attributes)
                    } else {
                        this.errorHandle();
                    }
                } else if (apiRequestCallId === this.saveProfileCallId) {
                    this.saveProfileAPIResponse(responseJson)
                } else if (apiRequestCallId === this.removeProfileCallId) {
                    this.removeProfileAPIResponse(responseJson)
                } else {
                    this.handleAPIResponse(apiRequestCallId, responseJson);
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].AccountDetails;
        let accessToken = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        if (!accessToken || !user) {
            this.handleNavigation("Home");
        }
        this.setState({
            currentLanguage: language,
            profile: profileFields(language),
            businessDocument: documentFields(language),
            business: businessUserProfileFields(language),
            kycVerification: kycUserProfileFields(language),
            pageHeading: heading
        })
        this.fetchProfile()
        // Customizable Area End
    }

    // Customizable Area Start

    isValidResponseAddress = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data) || responseJson.message;
    };

    handleAPIResponse = (apiCallID: string, response: ValidResponseType | InvalidResponseType) => {
        if (this.isValidResponseAddress(response as ValidResponseType)) {
            this.apiSuccessCallBacks(apiCallID, response as ValidResponseType);
        }
    };

    apiSuccessCallBacks = (apiCallID: string, response: ValidResponseType) => {
        if (apiCallID === this.updateDetailCallId) {
            this.handleBreadScrum("breadScrum0");
        }
        if (apiCallID === this.deleteAccountCallId) {
            this.errorHandle();
        }
    };

    profileAPIResponse = (responseJson: UserDataResponse) => {
        if (responseJson.data) {
            this.handleApiResponse(responseJson.data.attributes)
        } else {
            this.errorHandle();
        }
    }
    saveProfileAPIResponse = (responseJson: UserDataResponse) => {
        if (responseJson.data) {
            this.setState({ edit: null})
            this.handleApiResponse(responseJson.data.attributes);
            this.handleBreadScrum("breadScrum0")
        } else {
            this.errorHandle()
        }
    }
    removeProfileAPIResponse = async (responseJson: UserDataResponse) => {
        if (!responseJson.errors) {
            await setStorageData("profilePic", "image")
            window.dispatchEvent(new Event("storage"))
            this.setState({ edit: null, profilePicture: null })
        } else {
            this.errorHandle()
        }
    }
    errorHandle = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("profilePic");
        await removeStorageData("userType");
        await removeStorageData("userId");
        await removeStorageData("otpToken");
        await removeStorageData("signupStatus");
        this.handleNavigation("Home");
    }
    fetchProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    validateInputs = (): boolean => {
        let errorCount: number = 0
        const newProfile = this.state.profile.map(profile => {
            if (profile.value && !this.testRegex(profile.regex, `${profile.value}`)) {
                errorCount++;
                profile["error"] = "Invalid input"
            } else {
                profile["error"] = ""
            }
            return profile
        })
        this.setState({
            profile: newProfile
        })
        return errorCount === 0
    }
    saveProfile = async () => {

        const { password, profile, fullName } = this.state;

        const headers = {
            "token": await getStorageData("accessToken")
        }


        if (this.validateInputs()) {

            const formData = new FormData()
            formData.append("[data][attributes][full_name]", fullName );
            formData.append("[data][attributes][full_phone_number]", profile[3].value as string|Blob)
            formData.append("[data][attributes][city]", profile[5].value as string|Blob)
            formData.append("[data][attributes][state]", profile[6].value as string|Blob)
            formData.append("[data][attributes][country]", profile[7].value as string|Blob)
            formData.append("[data][attributes][zipcode]", profile[8].value as string|Blob)
            if (password) {
                formData.append("[data][attributes][password]", password );
            }

            const requestMessage = new Message(
                getName(MessageEnum.RestAPIRequestMessage)
            );
            this.saveProfileCallId = requestMessage.messageId;
            requestMessage.addData(
                getName(MessageEnum.RestAPIResponceEndPointMessage),
                configJSON.saveProfileEndPoint
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestHeaderMessage),
                JSON.stringify(headers)
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestBodyMessage),
                formData
            );

            requestMessage.addData(
                getName(MessageEnum.RestAPIRequestMethodMessage),
                configJSON.methodPut
            );

            runEngine.sendMessage(requestMessage.id, requestMessage);

        }
    }
    removeProfilePic = async () => {


        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.removeProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.removeProfilePicEndPoint
        );


        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    updateProfilePic = async (file: File) => {
        const headers = {
            "token": await getStorageData("accessToken")
        }

        const formData = new FormData()
        formData.append("[data][attributes][profile_image]", file)

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.fetchProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.updateProfilePicEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPut
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteAccount = async () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteAccountCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.deleteAccountEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        const headers = {
            "token": await getStorageData("accessToken")
        };
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    async updateDetailInformation () {
        const token = await getStorageData("accessToken");
        const headers = { "token": token};
        const { dateDay, dateMonth, dateYear, nationalId, idProof, addressProof } = this.state;
        let formdata = new FormData();
        formdata.append("date_of_birth", dateDay+"-"+dateMonth+"-"+dateYear);
        formdata.append("license_number", nationalId);
        if(addressProof) {
            formdata.append("address_proof_image", addressProof);
        }
        if(idProof) {
            formdata.append("id_proof_image", idProof);
        }
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateDetailCallId = requestMessage.messageId;
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          configJSON.updateDetailInfoEndPoint
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(headers)
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestBodyMessage),
          formdata
        );
    
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.loginAPiMethod
        );
    
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        }, () => this.handlePhotoUpMenuOpen())
    };

    handlePhotoUpMenuOpen = () => {
        let options = languageTranslationData[this.state.currentLanguage].breadScrumEditPhoto;
        options = Object.values(options);
        if(this.state.anchorEl&&this.state.anchorEl.id==="profilePicture")
            {
                this.setState({
                    showUploadMenu: true,
                    breadScrumArray: options
                });
            }
    };

    testRegex = (regex: RegExp, value: string): boolean => {
        return regex.test(value)
    }

    handleCloseMenu = () => {
        if(this.state.anchorEl && this.state.anchorEl.id==="profilePicture")
            {
                this.handleBreadScrum("breadScrum1")
            }
        this.setState({ anchorEl: null })
    }

    handleProfileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newProfile = this.state.profile.map(profile => {
            if (profile.id === event.target.name) {
                profile["value"] = event.target.value
            }
            return (profile)
        })
        this.setState({
            profile: newProfile
        }, ()=>this.handleErrorCheck())
    }
    handleBusinessChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const newBusiness = this.state.business.map(business => {
            if (business.id === event.target.name) {
                business["value"] = event.target.value
            }
            return (business)
        })
        this.setState({
            business: newBusiness
        })
    }

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }
    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const anchorId = this.state.anchorEl?.id;
        const file: File | null = event.target.files ? event.target.files[0] : null;
        if (anchorId) {
            if (anchorId === "profilePicture") {
                this.updateProfilePic(file as File)
                this.setState({
                    profilePicture: URL.createObjectURL(file as Blob | MediaSource),
                    anchorEl: null
                }, ()=>this.handleBreadScrum("breadScrum1"));
            } else {
                if(anchorId === "idProof"|| anchorId === "addressProof")
                    {
                        this.setState({
                            anchorEl: null,
                            [anchorId]: file
                        } as unknown as Pick<S, keyof S>);
                    }
            }
        }
    };

    handleAddDocument = (event: React.ChangeEvent<HTMLInputElement>, document: string) => {
        if(event.target.files) {
            this.setState({
                [document]: event.target.files[0]
            } as unknown as Pick<S, keyof S>);
        }
    };

    handleFileRemove = () => {
        const anchorId = this.state.anchorEl?.id
        if (anchorId) {
            if (anchorId === "profilePicture") {
                this.removeProfilePic()
                this.setState({
                    profilePicture: null,
                    anchorEl: null
                }, ()=>this.handleBreadScrum("breadScrum1"));
            } else {
                this.setState({
                    anchorEl: null
                });
            }
        }
    }

    resetProfile = async (resp: UserData['attributes']) => {
        let year: string, month: string, day: string;
        if(resp.date_of_birth) {
            [year, month, day] = resp.date_of_birth.split('-');
        } else {
            [year, month, day] = ["", "", ""];
        }
        const newProfile = this.state.profile.map(profile => {
            if ( profile.id !== "first_name") {
                profile.value = resp[profile.id];
            }
            return profile;
        });
        this.setState({
            profile: newProfile,
            fullName: resp.full_name, 
            formattedPhoneNumber : `${newProfile[3].value}`,
            dateDay: day,
            dateMonth: month,
            dateYear: year,
            nationalId: resp.license_number,
            idProof: await this.handleFileConvert(resp.images.id_proof_image),
            addressProof: await this.handleFileConvert(resp.images.address_proof_image)
        });
    };

    handleFileConvert = async (fileData: IDocumentInfo | undefined) => {
        let blob, file=null;
        if(fileData !== undefined && fileData.url !== null) {
            const response = await fetch(fileData.url);
            const fileArrayBuffer = await response.arrayBuffer();
            blob = new Blob([fileArrayBuffer], { type: 'text/plain' });
            file = new File([blob], fileData.file_name as string, { type: 'application/msword' });
        }
        return file;
    };

    resetKYC = (resp: UserData['attributes']) => {
        const newKYC = this.state.kycVerification.map(kycData => {
            kycData.value = resp[kycData.id];
            return kycData
        })
        this.setState({
            kycVerification: newKYC
        })
    }
    resetBusiness = (resp: UserData['attributes']) => {
        const newBusiness = this.state.business.map(business => {
            business.value = resp[business.id];
            return business
        })
        this.setState({
            business: newBusiness
        })
    }
    resetBusinessDocument = (resp: UserData['attributes']['images']) => {
        const newBusinessDocument = this.state.businessDocument.map(document => {
            document.file = resp[document.id] ? {
                name: resp[document.id].file_name,
                size: resp[document.id].size
            } as unknown as File : null
            return document
        })
        this.setState({
            businessDocument: newBusinessDocument
        })
    }
    handleApiResponse = (resp: UserData['attributes']) => {
        this.resetKYC(resp)
        this.resetProfile(resp)
        this.resetBusiness(resp)
        resp.images && this.resetBusinessDocument(resp.images)
        setStorageData("profilePic", resp.profile_image ?? "image")
        window.dispatchEvent(new Event("storage"))
        this.setState({
            response: resp,
            profilePicture: resp.profile_image
        })
    }

    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.errorHandle();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrum(route)
            } else {
                this.handleNavigation(route);
            }
        }
    };

    handleBreadScrum = (item: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        let options = languageData.breadScrumEdit;
        options = Object.values(options);
        const lastIndex = this.state.breadScrumArray.length - 1;
        const itemLastIndex = item.length - 1;
        const lastChar = item.charAt(itemLastIndex);
        if (Number(lastChar) === lastIndex) {
          return;
        } else {
          switch (item) {
            case ("breadScrum0"): {
              this.setState({
                editBasic: false,
                editDetail: false,
                commonHide: false,
                pageHeading: languageData.AccountDetails,
                checkSave: false,
                breadScrumArray: []
              }, ()=>this.fetchProfile());
              break;
            }
            case ("breadScrum1"): {
              this.setState({
                editBasic: true,
                editDetail: false,
                commonHide: true,
                pageHeading: languageData.EditBasic,
                showUploadMenu: false,
                breadScrumArray: options,
                anchorEl:null
              });
              break;
            }
          }
        }
      };

    handleEdit = (editSelect: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        let breadOptions = languageData.breadScrumEdit;
        breadOptions = Object.values(breadOptions);
        let heading, editBasic = false, editDetail = false;
        if (editSelect === "editBasic") {
            heading = languageData.EditBasic;
            editBasic = true
        } else {
            heading = languageData.EditDetailed;
            editDetail = true
        }
        this.setState({
            editBasic: editBasic,
            editDetail: editDetail,
            commonHide: true,
            pageHeading: heading,
            breadScrumArray: breadOptions
        });
    };

    handleChange =
    (prop: keyof S) => (event: React.ChangeEvent<HTMLInputElement>) => {
      this.setState(
        { [prop]: event.target.value } as unknown as Pick<S, keyof S>,
        () => this.handleErrorCheck()
      );
    };

    handleErrorCheck = () => {
        const errorsLanguage = languageTranslationData[this.state.currentLanguage].errors;
        let { fullName, profile, password, nationalId } = this.state;
        fullName = fullName.trim()
        const email = `${profile[2].value}`;
        const phoneNumber = profile[3].value;
        let fullNameError,
          emailError,
          phoneNumberError,
          passwordError,
          nationalIDError;
        if (!fullName) {
          fullNameError = errorsLanguage.fullNameRequired;
        }
        emailError = this.checkEmail(email);
        passwordError = this.checkPassword(password);
        phoneNumberError = this.checkPhoneNumber(`${phoneNumber}`);
        if (!nationalId) {
          nationalIDError = errorsLanguage.nationIDRequired;
        }
        this.setState({
          errorCheck: {
            nationalIDError: nationalIDError,
            passwordError: passwordError,
            fullNameError: fullNameError,
            emailError: emailError,
            phoneNumberError: phoneNumberError,
          }
        });
        return {
            nationalIDError: nationalIDError,
            passwordError: passwordError,
            fullNameError: fullNameError,
            emailError: emailError,
            phoneNumberError: phoneNumberError
          }
    };

    checkPhoneNumber = (phoneNumber:string)=>{
        const errorsLanguage = languageTranslationData[this.state.currentLanguage].errors
        let errorMessage;
        switch(true){
            case (!phoneNumber):{
                errorMessage = errorsLanguage.phoneNumberRequired;
                break;
            }
            case (phoneNumber.length<10):{
                errorMessage = errorsLanguage.invalidPhoneNumber;
                break;
            }
            default:{
                errorMessage = ""
            }
        }
        return errorMessage;
    }

    checkEmail = (email: string) => {
        let validRegex = configJSON.emailRegex;
        let errorMessage;
        const errorsLanguage = languageTranslationData[this.state.currentLanguage].errors;
        switch (true) {
          case email.toString().length === 0:
            errorMessage = errorsLanguage.emailRequired;
            break;
          case !validRegex.test(email):
            errorMessage = errorsLanguage.invalidEmail;
            break;
          default:
            errorMessage = "";
            break;
        }
        return errorMessage;
    };

    handlePopoverOpen = () => {
        this.checkPassword(this.state.password);
        this.setState({
          showPopover: !this.state.showPopover,
        });
    };

    handlePopupOptionLanguage = (index:number) => {
        let options = languageTranslationData[this.state.currentLanguage].passValidPoint;
        options = Object.values(options);
        return options[index];
    };

    checkPassword = (password: string) => {
        const errorsLanguage = languageTranslationData[this.state.currentLanguage].errors;
        let validUpperCase = configJSON.upperCaseRegex;
        let validLowerCase = configJSON.lowerCaseRegex;
        let validNumber = configJSON.digitCheckRegex;
        let errorMessage = "";
        let errorArray = [
          validUpperCase.test(password),
          validLowerCase.test(password),
          validNumber.test(password),
          password.length >= 8,
        ];
        let checkError = errorArray.every((item) => item);
        if (!checkError && password.length !== 0 ) {
          errorMessage = errorsLanguage.invalidPassword;
        } else {
          errorMessage = "";
        }
    
        this.setState((prevState) => ({
          passCheckArray: prevState.passCheckArray.map((item, index) => ({
            ...item,
            valid: errorArray[index],
          })),
          errorCheck: { ...prevState.errorCheck, passwordError: errorMessage },
        }));
        return errorMessage;
    };

    handleClickShowPassword = () => {
        this.setState({ showPassword: !this.state.showPassword });
    };

    handleZipChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const zipCode = value.replace(configJSON.replaceAlphabetRegex, "");
        const newProfile = this.state.profile.map(profile => {
            if (profile.id === event.target.name) {
                profile["value"] = zipCode
            }
            return (profile)
        })
        this.setState({
            profile: newProfile
        })
    };

    handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = event.target;
        const phoneNumber = value.replace(configJSON.replaceAlphabetRegex, "");
        const newProfile = this.state.profile.map(profile => {
            if (profile.id === event.target.name) {
                profile["value"] = phoneNumber;
            }
            return (profile)
        })
        this.setState({
            profile: newProfile,
            formattedPhoneNumber:phoneNumber
        }, ()=>this.handleErrorCheck())
    };

    handleMonthChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (value !== "" && (parseInt(value, 10) < 0 || parseInt(value, 10) > 12)) {
          value = "";
        }
        this.setState({
          dateMonth: value
        });
    };

    handleDayChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let value = event.target.value;
        let { dateMonth, dateYear } = this.state;
        let numberOfDays = 31;
        value = value.replace(configJSON.replaceAlphabetRegex, "");
        if (value.length > 2) {
          value = value.slice(0, 2);
        }
        if (dateMonth && dateYear) {
          numberOfDays = (new Date(Number(dateYear), Number(dateMonth), 0).getDate());
        } else if (dateMonth && !dateYear) {
          numberOfDays = (new Date(2001, Number(dateMonth), 0).getDate());
        }
        if (
          value !== "" &&
          (parseInt(value, 10) < 0 || parseInt(value, 10) > numberOfDays)
        ) {
          value = "";
        }
        this.setState({
          dateDay: value
        });
    };
    
    handleYearChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let inputYear = event.target.value.trim();
        const currentYear = new Date().getFullYear();
        const isValidYear = parseInt(inputYear, 10) >= 1 && parseInt(inputYear, 10) < currentYear;
        if (!isValidYear) {
          inputYear=""
        }
        this.setState({
          dateYear: inputYear
        });
    };
    
    handleBlur = (valueName: string) => {
        let { dateMonth, dateYear } = this.state;
        switch (valueName) {
          case "month": {
            if (dateMonth.length === 1 || dateMonth === "00") {
              if (dateMonth === "0" || dateMonth === "00") {
                dateMonth = "";
              } else {
                dateMonth = `0${dateMonth}`;
              }
            }
            break;
          }
          case "day": {
            this.handleDayBlur();
            break;
          }
          default: {
            if (Number(dateYear) < 1900) {
              dateYear="1900"
            }
            break;
          }
        }
        this.setState({
          dateMonth: dateMonth,
          dateYear: dateYear
        });
    };

    handleDayBlur = () => {
        let { dateDay } = this.state;
        if (dateDay.length === 1 || dateDay === "00") {
            if (dateDay === "0" || dateDay === "00") {
              dateDay = "";
            } else {
              dateDay = `0${dateDay}`;
            }
        }
        this.setState({
            dateDay: dateDay
          });
    };

    handleBasicSave = () => {
        this.setState({
          checkSave: true,
        });
        let getErrors = this.handleErrorCheck();
        let checkErrors = Object.values(getErrors).every((value, index) =>
            index !== 0 ? value === undefined || value.length === 0 : true
        );
        if(checkErrors){
            this.saveProfile();
        }
    };

    handleBasicReset = () => {
        const valueToReset = ["first_name", "last_name", "email", "full_phone_number", "city", "state", "country", "zipcode"];
        const newProfile = this.state.profile.map((profile,) => {
            valueToReset.forEach(item=>{
            if (profile.id === item && item !== "email") {
                profile["value"] = ""
            }});
            return (profile)
        })
        this.setState({
            profile: newProfile,
            checkSave: false,
            formattedPhoneNumber: "",
            fullName: "",
            password: ""
        },()=>this.checkPassword(this.state.password))
    };

    handleDetailedSave = () => {
        this.setState({
          checkSave: true,
        });
        this.handleErrorCheck();
        let getErrors = this.handleErrorCheck();
        let checkErrors = Object.values(getErrors).every((value, index) =>
            index === 0 ? value === undefined || value.length === 0 : true
        );
        if(checkErrors){
            this.updateDetailInformation();
        };
    };

    handleDetailedReset = () => {
        this.setState({
            checkSave: false,
            dateDay: "",
            dateMonth: "",
            dateYear: "",
            nationalId: "",
            idProof: null,
            addressProof: null
        });
    };

    handleModalOpen = () => {
        this.setState({showModal: true});
    };

    handleModalClose = () => {
        this.setState({showModal: false});
    };

    // Customizable Area End
}

const profileFields = (language: string) => [
    {
        "label": languageTranslationData[language].UserProfile.FirstName,
        "id": "first_name",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.LastName,
        "id": "last_name",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.Email,
        "id": "email",
        ...configJSON.commonFieldSchemaEmail,
        "regex": /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/
    },
    {
        "label": languageTranslationData[language].UserProfile.ContactNumber,
        "id": "full_phone_number",
        ...configJSON.commonFieldSchema,
        "regex": /^[0-9-]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.Address,
        "id": "address",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.City,
        "id": "city",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.State,
        "id": "state",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.Country,
        "id": "country",
        ...configJSON.commonFieldSchema,
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].UserProfile.Zip,
        "id": "zipcode",
        ...configJSON.commonFieldSchema,
        "regex": /^[0-9-]+$/
    }
];

const documentFields = (language: string):S['businessDocument'] => [
    {
        "label": languageTranslationData[language].UserProfile.BusinessRegistrationDocument,
        "id": "business_registration",
        "file": null,
        "error": "",
        "icon": documentIcon
    },
    {
        "label": languageTranslationData[language].UserProfile.TaxRegistrationDocument,
        "id": "tax_registration",
        "file": null,
        "error": "",
        "icon": documentIcon
    },
    {
        "label": languageTranslationData[language].UserProfile.OtherDocuments,
        "id": "license_certification",
        "file": null,
        "error": "",
        "icon": clipIcon
    },
    {
        "label": languageTranslationData[language].UserProfile.OtherDocuments,
        "id": "other_documents",
        "file": null,
        "error": "",
        "icon": clipIcon
    }
]

const businessUserProfileFields = (language: string):S['business'] => [
    {
        "label": languageTranslationData[language].businessUserProfile.BusinessName as string,
        "id": "business_name",
        "value": "",
        "error": "",
        "type": "text",
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].businessUserProfile.BusinessTaxNo as string,
        "id": "tax_number",
        "value": "",
        "error": "",
        "type": "text",
        "regex": /^[0-9-]+$/
    },
]

const kycUserProfileFields = (language: string):S['kycVerification'] => [
    {
        "label": languageTranslationData[language].kycUserProfile.Name,
        "id": "kyc_name",
        "value": "",
        "error": "",
        "type": "text",
        "regex": /^[A-Za-z ]+$/
    },
    {
        "label": languageTranslationData[language].kycUserProfile.Document,
        "id": "kyc_document",
        "value": "",
        "error": "",
        "type": "text",
        "regex": /^[A-Za-z0-9 ]+$/
    },
]