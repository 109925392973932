import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  styled,
  Tabs,
  Tab,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BlogDotImage } from "./assets";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import { configJSON, TabPanelProps, Blog } from "./CatalogueController";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBlogCards() {
    return (
      <>
        {this.getBlogApi().map((blog: Blog, index: number) => (
          <Grid
            data-testID="blogTestID"
            item
            xs={12}
            sm={6}
            md={4}
            lg={4}
            key={index}
            onClick={() => this.handleUserData(blog.id)}
          >
            <Box key={index} sx={webStyle.cardMainBox}>
              <CardImage src={blog.attributes.image?.url || ""} />
              <Box sx={webStyle.cardImgText}>{blog.category_name}</Box>
              <Box sx={webStyle.smallCard}>
                <Box style={webStyle.dateWrapperBox}>
                  <Typography
                    data-testID="UserDataTest"
                    style={webStyle.smallCardDate}
                  >
                    {this.formatDate(blog.attributes.created_at)}
                  </Typography>
                  <Avatar style={webStyle.blogDotImage} src={BlogDotImage} />
                  <Typography style={webStyle.smallCardDate}>
                   {blog.attributes.read_minutes}
                  </Typography>
                </Box>
                <Typography style={webStyle.smallCardHeading}>
                  {blog.attributes.title}
                </Typography>
                <Box style={webStyle.smallCardAvt}>
                  <Avatar
                    style={webStyle.avtImg}
                    src={blog.attributes.author_image?.url}
                  />
                  <Box>
                    <Typography style={webStyle.smallCardName}>
                      {blog.attributes.author.name}
                    </Typography>
                    <Typography style={webStyle.smallCardSubHead}>
                      {blog.attributes.author.bio}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Grid>
        ))}
      </>
    );
  }
  CustomTabPanel = (props: TabPanelProps) => {
    return (
      <Box
        role="tabpanel"
        hidden={props.value !== props.index}
        id={`simple-tabpanel-${props.index}`}
        aria-labelledby={`simple-tab-${props.index}`}
      >
        {props.value === props.index && <Box>{props.children}</Box>}
      </Box>
    );
  };
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <Box style={webStyle.mainBox}>
          <ImageTextCard
            image={this.state.bannerData.attributes.image}
            heading={
              <Box sx={webStyle.subBoxFlex}>
                {this.state.bannerData.attributes.banner_title}
                <Box style={webStyle.mainBoxHeadingText} component="span">
                  <Typography style={webStyle.headingHeiglightSubText}>
                    {this.state.bannerData.attributes.sub_text1}
                  </Typography>
                  <Typography style={webStyle.headingHeiglightText}>
                  {this.state.bannerData.attributes.sub_text2}
                  </Typography>

                </Box>
              </Box>
            }
            description={this.state.bannerData.attributes.banner_description}
            darkTheme={false}
            alignLeft={false}
          />
          <NavigationTabBox>
            <BlogTabs
              value={this.state.tabValue}
              data-testID={"tabTestID"}
              onChange={this.handleTabValueChange}
            >
              <BlogStyledTab
                label={configJSON.blogListAll}
                selected={this.state.tabValue === 0}
              />
              {this.state.data.map((category, index) => (
                <BlogStyledTab
                  key={index}
                  label={category.attributes.name}
                  selected={this.state.tabValue === index}
                />
              ))}
            </BlogTabs>
          </NavigationTabBox>
          <this.CustomTabPanel value={this.state.tabValue} index={0}>
            <Grid container spacing={4}>
              {this.renderBlogCards()}
            </Grid>
          </this.CustomTabPanel>
          {this.state.data.map((_, index) => (
            <this.CustomTabPanel value={this.state.tabValue} index={index + 1}>
              <Grid container spacing={4}>
                {this.renderBlogCards()}
              </Grid>
            </this.CustomTabPanel>
          ))}
          {this.state.itemsToShow < this.state.blogsShow.length && (
            <Box style={webStyle.moreButtonBox}>
              <MoreButton
                data-testID="showmoreTestID"
                onClick={this.handleShowMore}
              >
                {configJSON.moreButtonText}
              </MoreButton>
            </Box>
          )}
        </Box>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CardImage = styled(Avatar)({
  height: "280px",
  width: "387px",
  objectFit: "cover",
  borderRadius: "20px",
  "@media (max-width: 1280px)": {
    height: "250px",
    width: "330px",
    borderRadius: "16px",
  },
});
const MoreButton = styled(Button)({
  padding: "10px 16px 10px 16px",
  backgroundColor: "#1E3A8A",
  fontSize: 14,
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1E3A8A",
    boxShadow: "none",
  },
});
const NavigationTabBox = styled(Box)({
  display: "flex",
  height: "32px",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "200px",
  marginBottom: "45px",
});
const BlogTabs = styled(Tabs)({
  "& .MuiTabs-indicator": {
    display: "none",
  },
});
const BlogStyledTab = styled(Tab)(
  ({ selected }: { theme?: any; selected?: boolean }) => ({
    borderRadius: "48px",
    background: selected ? "#1D4ED8" : "transparent",
    fontWeight: 500,
    color: selected ? "#FFFFFF" : "#0F172A",
    fontSize: "16px",
    textTransform: "capitalize",
    border: "none",
    cursor: "pointer",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    "&.MuiTab-root": {
      padding: "10px 24px",
    },
  })
);
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  dateWrapperBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  mainBox: {
    padding: "80px 120px",
  },
  subBoxFlex: {
    display: "flex",
    flexDirection: "column",
    whiteSpace:"wrap"
  },
  mainBoxHeadingText: {
    display: "inline-block",
    fontWeight: "bold",
    borderRadius: "16px",
    padding: "6px",
    marginLeft: "-10px",
    fontSize: "30px",
  },
  headingHeiglightText: {
    display: "inline-block",
    backgroundColor: "#DBEAFE",
    fontWeight: "bold",
    color: "#1D4ED8",
    borderRadius: "16px",
    padding: "6px",
    marginLeft: "8px",
    fontSize: "30px",
  },
  headingHeiglightSubText: {
    display: "inline-block",
    fontWeight: "bold",
    color: "black",
    borderRadius: "16px",
    padding: "6px",
    marginLeft: "-4px",
    fontSize: "30px",
  },
  cardMainBox: {
    position: "relative",
    height: "438px",
    width: "387px",
    borderRadius: "26px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "20px",
    "@media (max-width: 1280px)": {
      height: "400px",
      width: "330px",
      gap: "10px",
      marginBottom: "15px",
    },
  },
  cardImgText: {
    position: "absolute",
    top: 8,
    left: 8,
    backgroundColor: "#DBEAFE",
    padding: "6px 12px 6px 12px",
    color: "#1D4ED8",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "26px",
  },
  smallCard: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    marginTop: "12px",
  },
  smallCardDate: {
    display: "flex",
    gap: "8px",
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
  },
  smallCardHeading: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#101828",
  },
  smallCardAvt: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  avtImg: {
    height: "48px",
    width: "48px",
    borderRadius: "50px",
  },
  smallCardName: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#334155",
  },
  smallCardSubHead: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
  },
  moreButtonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "64px",
  },
  blogDotImage: {
    height: "4px",
    width: "4px",
    opacity: "60%",
  },
};
// Customizable Area End
