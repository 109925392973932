Object.defineProperty(exports, "__esModule", {
  value: true
});

// Customizable Area Start
exports.productApiContentType = "application/json";
exports.apiMethodTypeGet = "GET";
exports.productAPiEndPoint = "catalogue/catalogues";
exports.cardImageText = "Debt Collection Strategies";
exports.smallcardDate = "Jan 17, 2024 • 7 min read";
exports.smallcardDate1 = "Jan 15, 2024 • 10 min read";
exports.smallcardDate2 = "Jan 12, 2024 • 8 min read";
exports.smallcardDate3 = "Jan 15, 2024 • 10 min read";
exports.smallcardDate4 = "Jan 17, 2024 • 7 min read";
exports.smallcardDate5 = "Jan 15, 2024 • 10 min read";
exports.smallcardHeading = "5 Strategies for Effective Debt Collection in 2024";
exports.smallcardHeading1 = "Navigating Regulatory Compliance in Debt Collection: A Guide for Businesses";
exports.smallcardHeading2 = "The Future of Debt Buying: Trends and Insights for Investors";
exports.smallcardHeading3 = "The Impact of Data Analytics on Debt Management: Leveraging Insights for Success";
exports.smallcardHeading4 = "Maximizing Returns: Tips for Selling Your Debt Portfolio on Online Platforms";
exports.smallcardHeading5 = "Best Practices for Negotiating Debt Settlements: A Comprehensive Guide";
exports.smallcardName = "Nick Gare";
exports.smallcardName1 = "Michael Smith";
exports.smallcardName2 = "Emily Davis";
exports.smallcardName3 = "Jason Patel";
exports.smallcardName4 = "Alex Rodriguez";
exports.smallcardName5 = "David Thompson";
exports.smallcardSubHead = "Debt Collection Specialist";
exports.smallcardSubHead1 = "Compliance Officer";
exports.smallcardSubHead2 = "Financial Analyst";
exports.smallcardSubHead3 = "Data Analyst";
exports.smallcardSubHead4 = "Business Development Manager";
exports.smallcardSubHead5 = "Legal Counsel";
exports.moreButtonText = "See more articles";
exports.mainHeadinText = "Insights and Expertise: Explore";
exports.mainHeadinSpanText = "Our";
exports.mainHeadinHeiglightText = "Debt Management Blog";
exports.blogListAll = "All";
exports.blogList1 = "Debt Collection Strategies";
exports.blogList2 = "Regulatory Compliance";
exports.blogList3 = "Debt Buying and Investment";
exports.blogList4 = "Debt Portfolio Management";
exports.blogList5 = "Industry Insights";
exports.blogAPIEndPoint = "bx_block_categories/categories";
exports.bannerID = "bx_block_content_management/banner/get_banner?slug=Service"












// Customizable Area End