import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import React, { ReactNode } from "react";
import { setStorageData } from "framework/src/Utilities";
export type BlogImage = {
  file_name: string;
  url: string;
  size: string;
};

export type Author = {
  id: number;
  name: string;
  bio: string;
  created_at: string;
  updated_at: string;
};

export type Image = {
  file_name: string;
  url: string;
  size: string;
};

export type Blog = {
  id: string;
  category_name?: string;
  type: string;
  attributes: {
    id: number;
    title: string;
    description: string;
    read_minutes: string,
    author_id: number;
    category_id: number;
    created_at: string;
    updated_at: string;
    image: BlogImage;
    author: Author;
    contents: string | null;
    attributes: CategoryAttributes;
    author_image: Image | null;
  };
};

export type CategoryAttributes = {
  id: number;
  name: string;
  dark_icon: string | null;
  dark_icon_active: string | null;
  dark_icon_inactive: string | null;
  light_icon: string | null;
  description: string;
  light_icon_active: string | null;
  light_icon_inactive: string | null;
  rank: number | null;
  created_at: string;
  updated_at: string;
  selected_sub_categories: string | null;
  blogs: {
    data: Blog[];
  };
};

export type CategoryState = {
  id: string;
  type: string;
  attributes: CategoryAttributes;
};
export type BannerAttributes = {
  id: number;
  type: string;
  attributes: {
    id: number;
    banner_title: string;
    banner_title_arabic: string;
    banner_description: string;
    banner_description_arabic: string;
    created_at: string;
    updated_at: string;
    image: string;
    sub_text1:string;
    sub_text2:string;
  };
};
export interface TabPanelProps {
  children: ReactNode;
  value: number;
  index: number;
}
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  // Customizable Area End
}

interface S {
  arrayHolder: any;
  token: string;
  // Customizable Area Start
  tabValue: number;
  data: CategoryState[];
  itemsToShow: number;
  blogs: Blog[];
  blogsShow: Blog[];
  selectedBlog: string;
  bannerData: BannerAttributes;
  // Customizable Area End
}

interface SS {
  id: any;
}

export default class CatalogueController extends BlockComponent<Props, S, SS> {
  getProductApiCallId: any;
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.SessionResponseMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      // Customizable Area Start
      arrayHolder: [],
      token: "",
      tabValue: 0,
      data: [],
      itemsToShow: 9,
      blogs: [],
      blogsShow: [],
      selectedBlog: "",
      bannerData: {
        id: 0,
        type: "",
        attributes: {
          id: 0,
          banner_title: "",
          banner_title_arabic: "",
          banner_description: "",
          banner_description_arabic: "",
          created_at: "",
          updated_at: "",
          image: "",
          sub_text1:"",
          sub_text2:"",
        },
      },

      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }

  async componentDidMount() {
    // Customizable Area Start
    super.componentDidMount();
    this.getToken();
    if (this.isPlatformWeb() === false) {
      this.props.navigation.addListener("willFocus", () => {
        this.getToken();
      });
    }
    this.getBlogRequest("");
    this.getBanner();
    // Customizable Area End
  }

  getToken = () => {
    const msg: Message = new Message(
      getName(MessageEnum.SessionRequestMessage)
    );
    this.send(msg);
  };

  getListRequest = (token: any) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
      token: token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.productAPiEndPoint
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  async receive(from: string, message: Message) {
    // Customizable Area Start
    runEngine.debugLog("Message Recived", message);
    if (getName(MessageEnum.SessionResponseMessage) === message.id) {
      let token = message.getData(getName(MessageEnum.SessionResponseToken));
      this.setState({ token: token, selectedBlog: "" });
      this.getBlogRequest("");
      // this.getBanner()
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getBannerCallID != null &&
      this.getBannerCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ bannerData: responseJson.data[0] });
      }
    }

    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getProductApiCallId != null &&
      this.getProductApiCallId ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      var responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ data: responseJson.data, selectedBlog: "" });
        this.handleData(responseJson.data);
      } else {
        var errorReponse = message.getData(
          getName(MessageEnum.RestAPIResponceErrorMessage)
        );
        this.parseApiCatchErrorResponse(errorReponse);
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  handleTabValueChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    if (newValue === 0) {
      this.handleDataChangeOnTab("All");
    } else {
      this.handleDataChangeOnTab(
        this.state.data[newValue - 1]?.attributes?.name
      );
    }

    this.setState({ tabValue: newValue });
    this.setState({ tabValue: newValue });
  };

  formatDate(dateString: string): string {
    const date = new Date(dateString);
    return new Intl.DateTimeFormat("en-GB", {
      day: "2-digit",
      month: "short",
      year: "numeric",
    }).format(date);
  }

  handleShowMore = () => {
    if (this.state.itemsToShow < this.state.blogs.length) {
      this.setState(() => ({
        itemsToShow: this.state.itemsToShow + 3,
      }));
    }
  };

  handleDataChangeOnTab = (label: string) => {
    if (label !== "All") {
      let newValue = this.state.blogs.filter((ele: any) => {
        return ele.category_name === label;
      });
      this.setState({ blogsShow: newValue });
    } else {
      this.setState({ blogsShow: this.state.blogs });
    }
  };

  handleData = (value: CategoryState[]) => {
    let data: Blog[] = [];

    value.forEach((element: CategoryState) => {
      if (element.attributes.blogs.data.length) {
        let newBlog: any = [];
        element.attributes.blogs.data.forEach((newElement: any) => {
          newElement.category_name = element.attributes.name;
          newBlog = [...newBlog, newElement];
        });

        data = [...data, ...newBlog];
      }
    });
    this.setState({ blogs: data, blogsShow: data });
  };
  handleUserData = (id: string) => {
    this.setState({ selectedBlog: id }, () =>
      this.handleNavigation("ContentManagement")
    );
  };

  async handleNavigation(route: string) {
    await setStorageData("selectedBlog", this.state.selectedBlog);
    const msg: Message = new Message(getName(MessageEnum.NavigationMessage));
    msg.addData(getName(MessageEnum.NavigationTargetMessage), route);
    msg.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    this.send(msg);
  }
  getBlogRequest = (which: string) => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getProductApiCallId = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `${configJSON.blogAPIEndPoint}${which}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  getBannerCallID: string = "";
  getBanner = () => {
    const header = {
      "Content-Type": configJSON.productApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getBannerCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.bannerID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.apiMethodTypeGet
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  getBlogApi = () => {
    return this.state.blogsShow.slice(0, this.state.itemsToShow);
  };

  // Customizable Area End
}
